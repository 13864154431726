import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/es6.function.name";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import router from "./router";
import store from "./store";
import NProgress from 'nprogress'; // Progress 进度条
import 'nprogress/nprogress.css'; // Progress 进度条样式
import { Message } from 'element-ui';
import { getTokens } from '@/utils/auth'; // 验权
import { setTitle } from '@/utils/util'; // 设置浏览器头部标题

// permission judge function
function hasPermission(roles, permissionRoles) {
  if (roles.indexOf('admin') >= 0) {} // admin permission passed directly
  if (!permissionRoles) return true;
  return roles.some(function (role) {
    return permissionRoles.indexOf(role) >= 0;
  });
}
var whiteList = ['/login']; // 不重定向白名单
router.beforeEach(function (to, from, next) {
  NProgress.start();
  if (getTokens()) {
    // 设置浏览器头部标题
    var browserHeaderTitle = to.name;
    store.commit('SET_BROWSERHEADERTITLE', {
      browserHeaderTitle: browserHeaderTitle
    });
    /* has token*/
    if (store.getters.isLock && to.path !== '/lock') {
      next({
        path: '/lock'
      });
      NProgress.done();
    } else if (to.path === '/login') {
      next({
        path: '/'
      });
      NProgress.done(); // if current page is dashboard will not trigger	afterEach hook, so manually handle it
    } else {
      if (store.getters.roles.length === 0) {
        store.dispatch('GetInfo').then(function (res) {
          // 拉取用户信息
          var roles = res.data.roles; // note: roles must be a array! such as: ['editor','develop']
          store.dispatch('GenerateRoutes', {
            roles: roles
          }).then(function () {
            // 根据roles权限生成可访问的路由表
            router.addRoutes(store.getters.addRouters); // 动态添加可访问路由表
            if (to.query.course == 1) {
              // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
              next(to.path + '?course=1');
            } else {
              next(_objectSpread(_objectSpread({}, to), {}, {
                replace: true
              }));
            }
          });
        }).catch(function (err) {
          store.dispatch('FedLogOut').then(function () {
            Message.error(err || 'Verification failed, please login again');
            next({
              path: '/'
            });
          });
        });
      } else {
        // 没有动态改变权限的需求可直接next() 删除下方权限判断 ↓
        if (hasPermission(store.getters.roles, to.meta.roles)) {
          next(); //
        } else {
          next({
            path: '/401',
            replace: true,
            query: {
              noGoBack: true
            }
          });
        }
      }
    }
  } else {
    // 设置浏览器头部标题
    var _browserHeaderTitle = to.name;
    store.commit('SET_BROWSERHEADERTITLE', {
      browserHeaderTitle: _browserHeaderTitle
    });
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next('/login');
      NProgress.done();
    }
  }
});
router.afterEach(function () {
  NProgress.done(); // 结束Progress
  setTimeout(function () {
    var browserHeaderTitle = store.getters.browserHeaderTitle;
    setTitle(browserHeaderTitle);
  }, 0);
});