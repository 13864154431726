import Cookies from 'js-cookie';
var app = {
  state: {
    // 中英文
    language: Cookies.get('language') || 'zh',
    sidebar: {
      opened: !+Cookies.get('sidebarStatus'),
      withoutAnimation: false
    },
    device: 'desktop'
  },
  mutations: {
    TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
      if (state.sidebar.opened) {
        Cookies.set('sidebarStatus', 1);
      } else {
        Cookies.set('sidebarStatus', 0);
      }
      state.sidebar.opened = !state.sidebar.opened;
      state.sidebar.withoutAnimation = false;
    },
    // 中英文
    SET_LANGUAGE: function SET_LANGUAGE(state, language) {
      state.language = language;
      Cookies.set('language', language);
    },
    CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
      Cookies.set('sidebarStatus', 1);
      state.sidebar.opened = false;
      state.sidebar.withoutAnimation = withoutAnimation;
    },
    TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
      state.device = device;
    }
  },
  actions: {
    ToggleSideBar: function ToggleSideBar(_ref) {
      var commit = _ref.commit;
      commit('TOGGLE_SIDEBAR');
    },
    // 中英文
    setLanguage: function setLanguage(_ref2, language) {
      var commit = _ref2.commit;
      commit('SET_LANGUAGE', language);
    },
    CloseSideBar: function CloseSideBar(_ref3, _ref4) {
      var commit = _ref3.commit;
      var withoutAnimation = _ref4.withoutAnimation;
      commit('CLOSE_SIDEBAR', withoutAnimation);
    },
    ToggleDevice: function ToggleDevice(_ref5, device) {
      var commit = _ref5.commit;
      commit('TOGGLE_DEVICE', device);
    }
  }
};
export default app;