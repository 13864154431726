var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "blue" }),
    _vm._v(" "),
    _c("span", { staticClass: "titlesr" }, [_vm._v(_vm._s(_vm.name))]),
    _vm._v(" "),
    _c("div", { staticClass: "tiemX" }, [
      _c("i", { staticClass: "el-icon-time" }),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.tiemS))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }