import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import nxScrollPane from '@/components/nx-scroll-pane';
import { generateTitle } from '@/utils/i18n';
export default {
  name: 'tagsView',
  components: {
    nxScrollPane: nxScrollPane
  },
  data: function data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {}
    };
  },
  computed: {
    visitedViews: function visitedViews() {
      return this.$store.state.tagsView.visitedViews;
    }
  },
  watch: {
    $route: function $route() {
      this.addViewTags();
      this.moveToCurrentTag();
    },
    visible: function visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu);
      } else {
        document.body.removeEventListener('click', this.closeMenu);
      }
    }
  },
  mounted: function mounted() {
    this.addViewTags();
  },
  methods: {
    generateTitle: generateTitle,
    // generateTitle by vue-i18n
    generateRoute: function generateRoute() {
      if (this.$route.name) {
        return this.$route;
      }
      return false;
    },
    isActive: function isActive(route) {
      return route.path === this.$route.path;
    },
    addViewTags: function addViewTags() {
      var route = this.generateRoute();
      if (!route) {
        return false;
      }
      this.$store.dispatch('addVisitedViews', route);
    },
    moveToCurrentTag: function moveToCurrentTag() {
      var tags = this.$refs.tag;
      // this.$nextTick(() => {
      //   for (const tag of tags) {
      //     if (tag.to === this.$route.path) {
      //       this.$refs.scrollPane.moveToTarget(tag.$el)
      //       break
      //     }
      //   }
      // })
    },
    closeSelectedTag: function closeSelectedTag(view) {
      var _this = this;
      this.$store.dispatch('delVisitedViews', view).then(function (views) {
        if (_this.isActive(view)) {
          var latestView = views.slice(-1)[0];
          if (latestView) {
            _this.$router.push(latestView.path);
          } else {
            _this.$router.push('/');
          }
        }
      });
    },
    closeOthersTags: function closeOthersTags() {
      var _this2 = this;
      this.$router.push(this.selectedTag.path);
      this.$store.dispatch('delOthersViews', this.selectedTag).then(function () {
        _this2.moveToCurrentTag();
      });
    },
    closeAllTags: function closeAllTags() {
      this.$store.dispatch('delAllViews');
      this.$router.push('/');
    },
    openMenu: function openMenu(tag, e) {
      this.visible = true;
      this.selectedTag = tag;
      this.left = e.clientX;
      this.top = e.clientY;
    },
    closeMenu: function closeMenu() {
      this.visible = false;
    }
  }
};