import _typeof from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/typeof.js";
import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import store from "../store";
import { getTokens } from '@/utils/auth';

// 创建axios实例
// console.log('-------------',process.env);
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API2,
  // api的base_url
  timeout: 5000000 // 请求超时时间 //zml改为60秒
});
// request拦截器
service.interceptors.request.use(function (config) {
  if (store.getters.token) {
    config.headers['token'] = getTokens(); // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  return config;
}, function (error) {
  // Do something with request error
  Promise.reject(error);
});
// respone拦截器
service.interceptors.response.use(function (response) {
  /**
   * code为非20000是抛错 可结合自己业务进行修改
   */
  var res = response.data;
  var code = response.code;
  if (code > 200 && code < 300 || code === 304) {
    if (response.data.code == '0000') {
      return Promise.resolve(response);
    }
  }
  if (res.code === 100032) {
    MessageBox.confirm(res.msg, '温馨提示', {
      confirmButtonText: '重新登录',
      showCancelButton: false,
      type: 'warning'
    }).then(function () {
      store.dispatch('FedLogOut').then(function () {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    });
    return response.data;
  }
  //token过期
  if (res.code === 100011) {
    MessageBox.confirm(res.msg, '温馨提示', {
      confirmButtonText: '重新登录',
      showCancelButton: false,
      type: 'warning',
      showClose: false
    }).then(function () {
      store.dispatch('FedLogOut').then(function () {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    });
    return response.data;
  }
  if (res.code === 100043) {
    MessageBox.confirm(res.msg, '温馨提示', {
      confirmButtonText: '重新登录',
      showCancelButton: false,
      type: 'warning',
      showClose: false
    }).then(function () {
      store.dispatch('FedLogOut').then(function () {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    });
    return response.data;
  }
  //////////
  if (res.code == 0) {
    return response.data;
  } else if (res.code == 100007) {
    return res.code;
  } else if (res.code == 100075) {
    return res;
  } else {
    var type = _typeof(res.msg);
    if (type == 'string') {
      Message({
        message: res.msg,
        type: 'warning',
        duration: 5 * 1000
      });
      return Promise.reject('error');
    } else if (type == 'object') {
      // let bb = Array.isArray(res.msg)
      // if (bb) {
      //     if (res.msg.some(item=> item instanceof Array)) {
      //     } else {
      //     }
      // } else {
      // }
      return res.msg;
    }
  }
}, function (error) {
  Message({
    message: error.msg,
    type: 'warning',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
export default service;