/*
 * @name: 张梦琳
 * @test: test font
 * @Date: 2021-01-22 10:54:39
 * @FilePath: \web_bcrm\src\api\PublicAPI.js
 * @msg:
 * @param:
 * @return:
 */

import request from '@/utils/request';
/*-------------------公用方法---------------------------- */

//部门接口zml
export function getStructureListArr(data) {
  return request({
    url: '/clientserver.php/Common/getStructureListArr',
    method: 'post',
    data: data
  });
}
//部门接口zml
export function getAPCStructureList(data) {
  return request({
    url: '/clientserver.php/ApprovalProcessConfiguration/getAPCStructureList',
    method: 'post',
    data: data
  });
}
//权限部门
export function getStructureListLow(data) {
  return request({
    url: '/clientserver.php/Common/get_lower_structure_list',
    method: 'post',
    data: data
  });
}
//角色接口zml
export function getGroupList(data) {
  return request({
    url: '/clientserver.php/Common/getGroupList',
    method: 'post',
    data: data
  });
}
//学员级别zml
export function getcustomerlevel(data) {
  return request({
    url: '/clientserver.php/Common/get_customer_level',
    method: 'post',
    data: data
  });
}
//院校列表zml
export function schoolList(data) {
  return request({
    url: '/clientserver.php/Common/schoolList',
    method: 'post',
    data: data
  });
}

//下次回访时间 显示多少数据

export function getUnfollow(data) {
  return request({
    url: '/clientserver.php/Common/getUnfollow',
    method: 'post',
    data: data
  });
}
export function AllCustomer(data) {
  return request({
    url: '/clientserver.php/AllCustomer/index',
    method: 'post',
    data: data
  });
}
//获取专业管理相关配置
export function getMajorBiz(data) {
  return request({
    url: '/clientserver.php/Common/get_major_biz',
    method: 'post',
    data: data
  });
}