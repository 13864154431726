//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AppMain',
  data: function data() {
    return {};
  },
  computed: {
    cachedViews: function cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key: function key() {
      return this.$route.fullPath;
    }
  },
  methods: {}
};