//
//
//
//
//
//
//
//
//
//
//
//
//

import nxSvgIcon from '@/components/nx-svg-icon/index';
export default {
  name: 'nx-lang-select',
  components: {
    nxSvgIcon: nxSvgIcon
  },
  computed: {
    language: function language() {
      return this.$store.getters.language;
    }
  },
  methods: {
    handleSetLanguage: function handleSetLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch('setLanguage', lang);
      this.$message({
        message: 'switch language success',
        type: 'success'
      });
    }
  }
};