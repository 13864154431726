import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.split";
import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { generateTitle } from '@/utils/i18n';
import { mapGetters } from 'vuex';
import bus from "../../mixin/bus";
import { log } from 'video.js';
import store from '@/store';
import { getStatusData } from '@/api/ceshi_xiugai/order';
export default {
  name: 'SidebarItem',
  props: {
    sidebaritemDatas: {
      type: Object
    },
    routes: {
      type: Array
    },
    isNest: {
      type: Boolean,
      default: false
    },
    activeNameEs: {
      type: String
    }
  },
  data: function data() {
    return {
      sidebaritem: {},
      reckontime: '',
      activeNames: '',
      staticId: 1,
      defaultArr: [],
      course: this.$route.query.course
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['sidebar'])), {}, {
    onRoutes: function onRoutes() {
      var url = this.$route.path.replace('/', '').split('/');
      return url[url.length - 1];
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  }),
  watch: {
    $route: function $route(to, from) {
      this.activeNames = '/' + to.path.split('/').splice(2, to.path.split('/').length).join('/');
      this.defaultArr = [this.activeNames.split('/').splice(0, 2).join('/'), this.activeNames.split('/').splice(0, 3).join('/'), this.activeNames];
    },
    activeNameEs: function activeNameEs(val) {
      return val;
    },
    sidebaritemDatas: {
      immediate: true,
      handler: function handler(val) {
        var course = this.$route.query.course;
        this.sidebaritem = this.sidebaritemDatas;
        var then = this;
        var router = this.sidebaritem.children;
        if (course == 1) {
          if (router !== undefined) {
            if (router[0].children === undefined) {
              then.$router.replace(this.sidebaritem.path + '/' + router[0].path + '?course=' + course);
            } else {
              then.$router.replace(this.sidebaritem.path + '/' + router[0].path + '/' + router[0].children[0].path + '?course=' + course);
              if (router[0].children[0].children) {
                then.$router.replace(this.sidebaritem.path + '/' + router[0].path + '/' + router[0].children[0].path + '/' + router[0].children[0].children[0].path + '?course=' + course);
              }
            }
          }
        } else {
          if (router !== undefined) {
            if (router[0].children === undefined) {
              then.$router.replace(this.sidebaritem.path + '/' + router[0].path);
            } else {
              then.$router.replace(this.sidebaritem.path + '/' + router[0].path + '/' + router[0].children[0].path);
              if (router[0].children[0].children) {
                then.$router.replace(this.sidebaritem.path + '/' + router[0].path + '/' + router[0].children[0].path + '/' + router[0].children[0].children[0].path);
              }
            }
          }
        }
      }
    }
  },
  methods: {
    statusData: function statusData() {
      // this.unreadMessage = JSON.parse(localStorage.getItem('unreadMgs'));
      // console.log('this.unreadMessage--',this.unreadMessage)
      // console.log('this.unreadMessage--',this.unreadMessage)
      // getStatusData().then((res) => {
      //     localStorage.setItem('unreadMgs', JSON.stringify(res.data));
      //     this.unreadMessage = res.data;
      // });
      // if (this.unreadMessage == undefined || this.unreadMessage == null) {
      //     getStatusData().then((res) => {
      //         console.log(123,res)
      //         localStorage.setItem('unreadMgs', JSON.stringify(res.data));
      //         this.unreadMessage = res.data;
      //     });
      // }
    },
    menuSelect: function menuSelect(key, keyPath) {
      window.clearInterval(this.reckontime);
      this.reckontime = null;
      localStorage.setItem('NavBasics', key);
    },
    layerFour: function layerFour(item, index) {},
    handleChange: function handleChange(val) {},
    Tow: function Tow(item) {
      // this.statusData();
      if (this.course == 1) {
        this.$router.replace(this.sidebaritem.path + '/' + item.path + '?course=' + this.course);
        localStorage.setItem('menuId', item.id);
      } else {
        this.$router.replace(this.sidebaritem.path + '/' + item.path);
      }
    },
    Three: function Three(item, subItem, threeItem) {
      // this.statusData();
      // if (
      //     (item.name = '订单管理' && subItem.name == '主管审核订单') ||
      //     (item.name = '订单管理' && subItem.name == '未通过订单') ||
      //     (item.name = '订单管理' && subItem.name == '已通过订单') ||
      //     (item.name = '财务管理' && subItem.name == '待审核订单')
      // ) {
      //     this.reckontime = window.setInterval(() => {
      //         // this.statusData();
      //         if (
      //             this.unreadMessage.audit_success_quantity == '0' &&
      //             this.unreadMessage.financial_audit_order_quantity == '0' &&
      //             this.unreadMessage.supervisor_audit_order_quantity == '0' &&
      //             this.unreadMessage.supervisor_audit_reject_quantity == '0' &&
      //             this.unreadMessage.unread_total_quantity == '0'
      //         ) {
      //             window.clearInterval(this.reckontime);
      //             this.reckontime = null;
      //         }
      //     }, 500);
      // } else {
      //     window.clearInterval(this.reckontime);
      //     this.reckontime = null;
      // }
      if (this.course == 1) {
        if (threeItem) {
          localStorage.setItem('menuId', threeItem.id);
          this.$router.replace(this.sidebaritem.path + '/' + item.path + '/' + subItem.path + '/' + threeItem.path + '?course=' + this.course);
        } else {
          localStorage.setItem('menuId', subItem.id);
          this.$router.replace(this.sidebaritem.path + '/' + item.path + '/' + subItem.path + '?course=' + this.course);
        }
      } else {
        if (threeItem) {
          this.$router.replace(this.sidebaritem.path + '/' + item.path + '/' + subItem.path + '/' + threeItem.path);
        } else {
          this.$router.replace(this.sidebaritem.path + '/' + item.path + '/' + subItem.path);
        }
      }
    }
  },
  created: function created() {
    // this.statusData();
    var sss = this.$router.history.current.path;
    var path = String(sss);
    this.activeNames = '/' + path.split('/').splice(2, path.split('/').length).join('/');
    this.defaultArr = [this.activeNames.split('/').splice(0, 2).join('/'), this.activeNames.split('/').splice(0, 3).join('/'), this.activeNames];
  },
  mounted: function mounted() {
    if (JSON.parse(JSON.stringify(this.sidebaritemDatas))) {
      this.sidebaritem = this.$store.getters.sidebarltem_arr;
    }
  }
};