import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';
import { SystemManualInfo, SystemManualEdit, OperatorDataIndex } from '@/api/article';
import { getStructureListArr } from '@/api/PublicAPI';
export default {
  components: {
    Head: Head,
    quillEditor: quillEditor
  },
  data: function data() {
    return {
      //系统教程
      tabShows: false,
      tutorialEs: false,
      prohibit: false,
      dialogFormVisible: false,
      centerHtml: '',
      content: '',
      editorOption: {
        placeholder: 'Hello World'
      },
      menuId: localStorage.getItem('menuId'),
      activeName: 'first',
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      BuriedData1: [],
      Buried: {
        search_username: '',
        search_realname: '',
        search_create_time: '',
        search_structure_ids: '',
        search_auth_rule_title: ''
      },
      typeShow: false,
      userNameshow: false,
      operatorShow: false,
      BelongingShow: false,
      operationDataShow: false,
      finalFollow: '',
      start_follow_time: '',
      //跟进时间（开始）
      end_follow_time: '',
      //跟进时间（结束）
      bumenoptions: [],
      //组织部门
      structure_id: '' //组织部门
    };
  },
  name: 'system',
  props: {
    title: String,
    tab: Number
  },
  mounted: function mounted() {
    var _this = this;
    this.getStructure();
    this.obtainId();
    SystemManualInfo({
      role_id: this.menuId
    }).then(function (res) {
      _this.centerHtml = res.data.contents;
    });
  },
  methods: {
    getStructure: function getStructure() {
      var _this2 = this;
      getStructureListArr().then(function (respomse) {
        _this2.bumenoptions = respomse.data;
      });
    },
    obtainId: function obtainId() {
      var _this3 = this;
      var jurisdiction = [];
      var tion = this.$store.getters.StatisTics;
      if (tion[0] != undefined) {
        tion.forEach(function (item) {
          jurisdiction.push(item.title);
        });
        if (jurisdiction.indexOf('页面操作统计') <= -1) {
          this.$nextTick(function () {
            _this3.$refs.tabs.$children[0].$refs.tabs[0].style.display = 'none';
            _this3.$refs.tabs.$children[0].$refs.tabs[1].style.display = 'none';
          });
        }
        if (jurisdiction.indexOf('编辑教程') > -1) {
          this.tutorialEs = true;
        } else {
          this.tutorialEs = false;
        }
      } else {
        this.$nextTick(function () {
          _this3.$refs.tabs.$children[0].$refs.tabs[0].style.display = 'none';
          _this3.$refs.tabs.$children[0].$refs.tabs[1].style.display = 'none';
        });
        this.tutorialEs = false;
      }
    },
    detailsClick: function detailsClick() {
      var _this4 = this;
      //系统教程
      SystemManualInfo({
        role_id: this.menuId
      }).then(function (res) {
        _this4.content = res.data.contents;
        _this4.dialogFormVisible = true;
      });
    },
    updateData: function updateData() {
      var _this5 = this;
      //系统教程
      this.prohibit = true;
      SystemManualEdit({
        role_id: this.menuId,
        contents: this.content
      }).then(function (res) {
        _this5.prohibit = false;
        _this5.dialogFormVisible = false;
        _this5.$notify({
          title: '成功',
          message: '编辑成功',
          type: 'success'
        });
        SystemManualInfo({
          role_id: _this5.menuId
        }).then(function (res) {
          _this5.centerHtml = res.data.contents;
        });
      });
    },
    // 埋点列表筛选
    operatorClick: function operatorClick() {
      //操作人
      this.query.page = 1;
      this.statisticalTable(this.query.page, this.query.pagesize);
    },
    userNamesClick: function userNamesClick() {
      //用户名
      this.query.page = 1;
      this.statisticalTable(this.query.page, this.query.pagesize);
    },
    handleClick: function handleClick() {
      if (this.activeName == 'second') {
        this.query.page = 1;
        this.statisticalTable(this.query.page, this.query.pagesize);
      }
    },
    noteAppendedClick: function noteAppendedClick() {
      this.query.page = 1;
      if (this.course != 1) {
        this.getList(this.query.page, this.query.pagesize);
      } else {
        this.statisticalTable(this.query.page, this.query.pagesize);
      }
    },
    FinalFollowUpEs: function FinalFollowUpEs() {
      //最后跟进日期筛选
      this.query.page = 1;
      if (this.finalFollow == null) {
        this.start_follow_time = '';
        this.end_follow_time = '';
        this.Buried.search_create_time = this.start_follow_time + ',' + this.end_follow_time;
        this.statisticalTable(this.query.page, this.query.pagesize);
      } else {
        this.start_follow_time = this.finalFollow[0].toString().substring(0, 10);
        this.end_follow_time = this.finalFollow[1].toString().substring(0, 10);
        this.Buried.search_create_time = this.start_follow_time + ',' + this.end_follow_time;
        this.statisticalTable(this.query.page, this.query.pagesize);
      }
    },
    //组织部门
    structureclick: function structureclick(val) {
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 部门的最后一位ID
      var adad = share == ',' ? '' : share;
      this.query.page = 1;
      this.Buried.search_structure_ids = adad;
      this.statisticalTable(this.query.page, this.query.pagesize);
    },
    statisticalTable: function statisticalTable(page, pagesize) {
      var _this6 = this;
      this.Buried.page = page;
      this.Buried.pagesize = pagesize;
      this.Buried.auth_rule_pid = this.menuId;
      OperatorDataIndex(this.Buried).then(function (res) {
        _this6.BuriedData1 = res.data.data;
        _this6.query.total = res.data.total;
      });
    },
    //表头显示搜索框
    Activeshow: function Activeshow(item) {
      if (item === '操作人') {
        this.operatorShow = true;
      } else if (item === '用户名') {
        this.userNameshow = true;
      } else if (item === '操作日期') {
        this.operationDataShow = true;
      } else if (item === '操作人所属部门') {
        this.BelongingShow = true;
      } else if (item === '操作类型') {
        this.typeShow = true;
      }
    },
    //点×
    Deletesshow: function Deletesshow(item) {
      this.query.page = 1;
      if (item === '操作人') {
        this.operatorShow = false;
        this.Buried.search_realname = '';
      } else if (item === '用户名') {
        this.userNameshow = false;
        this.Buried.search_username = '';
      } else if (item === '操作日期') {
        this.operationDataShow = false;
        this.Buried.search_create_time = '';
      } else if (item === '操作人所属部门') {
        this.BelongingShow = false;
        this.structure_id = '';
        this.Buried.search_structure_ids = '';
      } else if (item === '操作类型') {
        this.typeShow = false;
        this.Buried.search_auth_rule_title = '';
      }
      this.statisticalTable(this.query.page, this.query.pagesize);
    },
    //每页最多显示条数10   20   30   50
    handleSizeChange: function handleSizeChange(val) {
      this.query.pagesize = val;
      this.statisticalTable(this.query.page, val);
    },
    //翻页
    handleCurrentChange: function handleCurrentChange(val) {
      this.query.page = val;
      this.statisticalTable(val, this.query.pagesize);
    }
  }
};