import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.replace";
import { login, logout, getInfo, sendmsg, layoutRead } from '@/api/login';
import { getTokens, setToken, removeToken, getmsgid, setmsgid } from '@/utils/auth';
import { setStore, getStore, removeStore } from '@/utils/store';
import { connect } from 'echarts/lib/echarts';
import '@/utils/globalBus';
import { notify } from 'element-ui';
var user = {
  state: {
    token: getTokens(),
    id: '',
    name: '',
    TianRun: {},
    avatar: '',
    roles: [],
    structure_name: '',
    group_title: '',
    username: '',
    mobile: '',
    hiredate: '',
    positive_date: '',
    realname: '',
    Msgid: '',
    client_id: '',
    //是否是项目方
    is_director: '',
    //是否是主管 1是 0否
    JsonArr1: '',
    Atter: [],
    StatisTics: '',
    isLock: getStore({
      name: 'isLock'
    }) || false,
    lockPasswd: getStore({
      name: 'lockPasswd'
    }) || '',
    browserHeaderTitle: getStore({
      name: 'browserHeaderTitle'
    }) || 'NxAdmin',
    unread_message: JSON.parse(localStorage.getItem('unreadMessage')) || {
      unread_total_quantity: 0,
      financial_audit_order_quantity: 0,
      supervisor_audit_order_quantity: 0,
      supervisor_audit_reject_quantity: 0,
      audit_success_quantity: 0
    },
    is_data_type: '' //用户数据类型是否必填
  },
  mutations: {
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_Atter: function SET_Atter(state, Atter) {
      state.Atter = Atter;
    },
    SET_TianRun: function SET_TianRun(state, TianRun) {
      state.TianRun = TianRun;
    },
    SET_JsonArr: function SET_JsonArr(state, JsonArr1) {
      state.JsonArr1 = JsonArr1;
    },
    SET_StatisTics: function SET_StatisTics(state, StatisTics) {
      state.StatisTics = StatisTics;
    },
    SET_NAME: function SET_NAME(state, name) {
      state.name = name;
    },
    SET_ID: function SET_ID(state, id) {
      state.id = id;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_CALLNAME: function SET_CALLNAME(state, callname) {
      state.callname = callname;
    },
    SET_CALLPASS: function SET_CALLPASS(state, callpass) {
      state.callpass = callpass;
    },
    SET_ISSHI: function SET_ISSHI(state, isShi) {
      state.isShi = isShi;
    },
    //zml部门名称
    SET_structure_name: function SET_structure_name(state, structure_name) {
      state.structure_name = structure_name;
    },
    SET_structure_id: function SET_structure_id(state, structure_id) {
      state.structure_id = structure_id;
    },
    //zml角色名字
    SET_group_title: function SET_group_title(state, group_title) {
      state.group_title = group_title;
    },
    //zml账号
    SET_username: function SET_username(state, username) {
      state.username = username;
    },
    //zml手机号
    SET_mobile: function SET_mobile(state, mobile) {
      state.mobile = mobile;
    },
    //zml入职日期
    SET_hiredate: function SET_hiredate(state, hiredate) {
      state.hiredate = hiredate;
    },
    //zml转正日期
    SET_positive_date: function SET_positive_date(state, positive_date) {
      state.positive_date = positive_date;
    },
    SET_realname: function SET_realname(state, realname) {
      state.realname = realname;
    },
    SET_delivery: function SET_delivery(state, delivery) {
      state.delivery = delivery;
    },
    SET_Msgid: function SET_Msgid(state, Msgid) {
      state.Msgid = Msgid;
    },
    //client_id是否是项目方
    SET_client_id: function SET_client_id(state, client_id) {
      state.client_id = client_id;
    },
    //是否是主管 1是 0否
    SET_is_director: function SET_is_director(state, is_director) {
      state.is_director = is_director;
    },
    //call
    // SET_call: (state, call) => {
    //     state.call = call;
    // },
    SET_LOCK_PASSWD: function SET_LOCK_PASSWD(state, lockPasswd) {
      state.lockPasswd = lockPasswd;
      setStore({
        name: 'lockPasswd',
        content: state.lockPasswd,
        type: 'session'
      });
    },
    SET_LOCK: function SET_LOCK(state, action) {
      state.isLock = true;
      setStore({
        name: 'isLock',
        content: state.isLock,
        type: 'session'
      });
    },
    CLEAR_LOCK: function CLEAR_LOCK(state, action) {
      state.isLock = false;
      state.lockPasswd = '';
      removeStore({
        name: 'lockPasswd'
      });
      removeStore({
        name: 'isLock'
      });
    },
    SET_BROWSERHEADERTITLE: function SET_BROWSERHEADERTITLE(state, action) {
      state.browserHeaderTitle = action.browserHeaderTitle;
    },
    SET_UNREAD_MESSAGE: function SET_UNREAD_MESSAGE(state, data) {
      state.unread_message = data;
    },
    SET_IS_DATA_TYPE: function SET_IS_DATA_TYPE(state, data) {
      state.is_data_type = data;
    }
  },
  actions: {
    // 登录
    Login: function Login(_ref, userInfo) {
      var _this = this;
      var commit = _ref.commit;
      var that = this;
      var username = userInfo.username.trim();
      return new Promise(function (resolve, reject) {
        login(username, userInfo.password).then(function (response) {
          var data = response.data;
          setToken(data.token);
          commit('SET_TOKEN', data.token);
          var ids = data.info.id;
          if (ids != '' && ids != null) {
            var baseUrl = process.env.VUE_APP_BASE_API3;
            var socket = io(baseUrl);
            socket.on('connect', function () {
              socket.emit('login', ids);
            });
            socket.on('new_msg', function (msg) {
              var fixMsg = JSON.parse(msg.replace(/&quot;/g, '"'));
              console.log('登录监听到新消息了', fixMsg);
              if (fixMsg.type === 'notice') {
                var arrEntities = {
                  lt: '<',
                  gt: '>',
                  nbsp: ' ',
                  amp: '&',
                  quot: '"'
                };
                fixMsg.content = fixMsg.content.replace(/&(lt|gt|nbsp|amp|quot);/g, function (all, t) {
                  return arrEntities[t];
                });
                var str = '';
                str = fixMsg.content.replace(/&lt;/g, '<');
                str = str.replace(/&gt;/g, '>');
                str = str.replace(/nbsp;/g, ' ');
                that._vm.$alert("<h2 class='msg-title'>".concat(fixMsg.title, "</h2>") + fixMsg.content, '系统公告', {
                  dangerouslyUseHTMLString: true,
                  lockScroll: true,
                  customClass: 'message'
                });
              }
              if (fixMsg.to_uid == ids) {
                sendmsg().then(function (res) {
                  _this.newsCliEs = res.data.unread_num;
                  localStorage.setItem('shuliang', _this.newsCliEs);
                });
                if (fixMsg.title == '撞单提醒') {
                  that._vm.$notify({
                    title: fixMsg.title,
                    dangerouslyUseHTMLString: true,
                    message: "\n                                                <div class=\"lh24 g6 mt20\">\n                                                <div class=\"\">\u6D88\u606F\u5185\u5BB9\uFF1A".concat(fixMsg.content, " </div>\n                                                <div class=\"\">\u6DFB\u52A0\u65F6\u95F4\uFF1A").concat(fixMsg.create_time, "</div>\n                                                <div class=\"tc poi mt20  blueFont\" id=\"btn\">\u8BBE\u7F6E\u6210\u5DF2\u8BFB</div>\n                                                </div>"),
                    position: 'bottom-right',
                    duration: 20000
                  });
                } else {
                  if (fixMsg.type == 8 || fixMsg.type == 9) {
                    that._vm.$notify({
                      title: fixMsg.title,
                      dangerouslyUseHTMLString: true,
                      message: "\n                                                    <div class=\"lh24 g6 mt20\">\n                                                    <div class=\"\">\u6D88\u606F\u5185\u5BB9\uFF1A".concat(fixMsg.content, " </div>\n                                                    <div class=\"\">\u6DFB\u52A0\u65F6\u95F4\uFF1A").concat(fixMsg.create_time, "</div>\n                                                    <div class=\"tc poi mt20  blueFont\" id=\"btn\">\u8BBE\u7F6E\u6210\u5DF2\u8BFB</div>\n                                                    </div>"),
                      position: 'bottom-right',
                      duration: 0,
                      onClick: function onClick() {
                        layoutRead({
                          ids: fixMsg.id
                        }).then(function (respomse) {
                          // instance.close();
                          sendmsg().then(function (res) {
                            _this.newsCliEs = res.data.unread_num;
                            localStorage.setItem('shuliang', _this.newsCliEs);
                          });
                        });
                      }
                    });
                  } // 预约短信提醒
                  else if (fixMsg.type == '12') {
                    var h = _this.$createElement;
                    _this.instance = that._vm.$notify({
                      title: fixMsg.title,
                      dangerouslyUseHTMLString: true,
                      message: h('div', {
                        class: 'lh24 g6 mt20'
                      }, [h('div', null, "".concat(fixMsg.content)), h('div', null, "\u6DFB\u52A0\u65F6\u95F4\uFF1A".concat(fixMsg.create_time)), h('div', {
                        style: 'text-align:center;margin-top:20px;color: #409EFF;cursor: pointer;',
                        on: {
                          click: function click() {
                            layoutRead({
                              ids: fixMsg.id
                            }).then(function (respomse) {
                              _this.instance.close();
                              sendmsg().then(function (res) {
                                _this.newsCliEs = res.data.unread_num;
                                localStorage.setItem('shuliang', _this.newsCliEs);
                              });
                            });
                          }
                        }
                      }, '设为已读')]),
                      position: 'bottom-right',
                      duration: 0
                    });
                  } else {
                    that._vm.$notify({
                      title: fixMsg.title,
                      dangerouslyUseHTMLString: true,
                      message: "\n                                                    <div class=\"lh24 g6 mt20\">\n                                                    <div class=\"\">\u6D88\u606F\u5185\u5BB9\uFF1A".concat(fixMsg.content, " </div>\n                                                    <div class=\"\">\u6DFB\u52A0\u65F6\u95F4\uFF1A").concat(fixMsg.create_time, "</div>\n                                                    <div class=\"tc poi mt20  blueFont\" id=\"btn\">\u8BBE\u7F6E\u6210\u5DF2\u8BFB</div>\n                                                    </div>"),
                      position: 'bottom-right',
                      duration: 10000,
                      onClick: function onClick() {
                        layoutRead({
                          ids: fixMsg.id
                        }).then(function (respomse) {
                          // instance.close();
                          sendmsg().then(function (res) {
                            _this.newsCliEs = res.data.unread_num;
                            localStorage.setItem('shuliang', _this.newsCliEs);
                          });
                        });
                      }
                    });
                  }
                }
                return _this.newsCliEs;
              }
              if (fixMsg.type == 'unreadMessage') {
                commit('SET_UNREAD_MESSAGE', fixMsg);
                localStorage.setItem('unreadMessage', JSON.stringify(fixMsg));
                // this.$store.commit('SET_UNREAD_MESSAGE', fixMsg);
              }
            });

            // 后端推送来在线数据时
            socket.on('update_online_count', function (online_stat) {
              console.log(online_stat);
            });
          }
          resolve(data);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 获取用户信息
    GetInfo: function GetInfo(_ref2) {
      var commit = _ref2.commit,
        state = _ref2.state;
      return new Promise(function (resolve, reject) {
        console.log('state.token', state.token);
        getInfo(state.token).then(function (response) {
          var map1 = response.data;
          var data = [];
          data.push(map1);
          data.map(function (item) {
            item.roles = item.group_title.split();
          });
          if (data[0].roles && data[0].roles.length > 0) {
            // 验证返回的roles是否是一个非空数组
            commit('SET_ROLES', data[0].roles);
          } else {
            reject('getInfo: roles must be a non-null array !');
          }
          commit('SET_NAME', data[0].realname);
          commit('SET_ID', data[0].id);
          localStorage.setItem('id', data[0].id);
          // commit('SET_CALLNAME', map1.call.call_name);
          // commit('SET_CALLPASS', map1.call.call_pass);
          //zml
          commit('SET_structure_name', data[0].structure_name);
          commit('SET_structure_id', data[0].structure_id);
          commit('SET_group_title', data[0].group_title);
          commit('SET_username', data[0].username);
          commit('SET_mobile', data[0].mobile);
          commit('SET_hiredate', data[0].hiredate);
          commit('SET_positive_date', data[0].positive_date);
          commit('SET_realname', data[0].realname);
          commit('SET_delivery', data[0].has_delivery);
          // commit('SET_call', data[0].call);
          commit('SET_client_id', data[0].client_id);
          commit('SET_ISSHI', data[0].is_shi);
          commit('SET_is_director', data[0].is_director);
          commit('SET_TianRun', data[0].call_tianrun);
          commit('SET_IS_DATA_TYPE', data[0].is_data_type);
          resolve(response);
          // if (map1.call.call_name != '' || map1.call.call_name != undefined) {
          //     localStorage.setItem('state1', 'hangup');
          // }
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 登出
    LogOut: function LogOut(_ref3) {
      var commit = _ref3.commit,
        state = _ref3.state;
      return new Promise(function (resolve, reject) {
        logout(state.token).then(function () {
          if (typeof qiConnect !== 'undefined') {
            if (window.isCalling) qiConnect.bye();
            qiConnect.logoff();
          }
          commit('SET_TOKEN', '');
          commit('SET_ROLES', []);
          commit('CLEAR_LOCK');
          removeToken();
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 前端 登出
    FedLogOut: function FedLogOut(_ref4) {
      var commit = _ref4.commit;
      return new Promise(function (resolve) {
        commit('SET_TOKEN', '');
        removeToken();
        resolve();
      });
    },
    // 动态修改权限
    ChangeRoles: function ChangeRoles(_ref5, role) {
      var commit = _ref5.commit;
      return new Promise(function (resolve) {
        commit('SET_TOKEN', role);
        setToken(role);
        getInfo(role).then(function (response) {
          var data = response;
          commit('SET_ROLES', data.roles);
          commit('SET_NAME', data.name);
          resolve();
        });
      });
    },
    Atter: function Atter(_ref6, all) {
      var commit = _ref6.commit;
      commit('SET_Atter', all);
    },
    JsonArr1: function JsonArr1(_ref7, _JsonArr) {
      var commit = _ref7.commit;
      commit('SET_JsonArr', _JsonArr);
    },
    statistics: function statistics(_ref8, _statistics) {
      var commit = _ref8.commit;
      commit('SET_StatisTics', _statistics);
    },
    unreadMessage: function unreadMessage(_ref9, data) {
      var commit = _ref9.commit;
      commit('SET_UNREAD_MESSAGE', data);
    }
  }
};
export default user;