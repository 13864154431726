export default {
  getTimeInterval: function getTimeInterval(time) {
    //time为减去的指定天数
    var date1 = new Date();
    var date2 = new Date(date1);
    // -30为30天前，+30可以获得30天后的日期
    date2.setDate(date1.getDate() - time);
    // 倒退几天
    var agoDay = "".concat(date2.getFullYear()).concat(date2.getMonth() + 1 < 10 ? "-0".concat(date2.getMonth() + 1) : "-".concat(date2.getMonth() + 1)).concat(date2.getDate() < 10 ? "-0".concat(date2.getDate()) : "-".concat(date2.getDate()));
    // 当前日期
    var nowDay = "".concat(date1.getFullYear()).concat(date1.getMonth() + 1 < 10 ? "0".concat(date1.getMonth() + 1) : date1.getMonth() + 1).concat(date1.getDate() < 10 ? "0".concat(date1.getDate()) : date1.getDate());
    var obj = {
      startTime: agoDay,
      endTime: nowDay
    };
    return obj;
  }
};