/*
 * @Author: your name
 * @Date: 2021-04-13 18:28:19
 * @LastEditTime: 2021-05-17 14:42:23
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \CRM1\src\utils\util.js
 */
/**
 * 动态插入css
 */

export var loadStyle = function loadStyle(url) {
  var link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.href = url;
  var head = document.getElementsByTagName('head')[0];
  head.appendChild(link);
};
/**
 * 设置浏览器头部标题
 */
export var setTitle = function setTitle(title) {
  title = title ? "".concat(title) : 'CRM';
  window.document.title = title;
};

/**
 * 页面是否可见
 * cb：回调函数
 * showStatus:show | hide 指定回调函数在页面显示/隐藏时执行，不指定全部状态都执行
 */
var hidden, state, visibilityChange, funcb, showStatus;
export var pageVisit = function pageVisit(cb) {
  var showStatus = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'show';
  funcb = cb;
  showStatus = showStatus;
  if (typeof document.hidden !== "undefined") {
    hidden = "hidden";
    visibilityChange = "visibilitychange";
    state = "visibilityState";
  } else if (typeof document.mozHidden !== "undefined") {
    hidden = "mozHidden";
    visibilityChange = "mozvisibilitychange";
    state = "mozVisibilityState";
  } else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
    state = "msVisibilityState";
  } else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
    state = "webkitVisibilityState";
  }
  // 如果监听事件的函数是匿名函数，那么是没办法取消的，
  // 所以，一般监听事件，后面跟着的是一个具名函数，
  // 相当于，监听这件事，并且配上事件的处理函数，取消监听的时候，也需要配对，
  // 刚才的事件名和处理函数一起，取消掉
  document.addEventListener(visibilityChange, pageVisit1, false);
};
var pageVisit1 = function pageVisit1() {
  if (!showStatus) {
    funcb();
    return;
  }
  if (showStatus === 'show' && !document[hidden]) {
    funcb();
    return;
  }
  if (showStatus === 'hide' && document[hidden]) {
    funcb();
    return;
  }
};

/**
 * 移除绑定页面是否可见函数removeEventListener
 */
export var removePageVisit = function removePageVisit() {
  document.removeEventListener(visibilityChange, pageVisit1);
};