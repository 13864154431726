var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "scrollContainer",
      staticClass: "scroll-container",
      on: {
        wheel: function ($event) {
          $event.preventDefault()
          return _vm.handleScroll($event)
        },
      },
    },
    [
      _c(
        "div",
        {
          ref: "scrollWrapper",
          staticClass: "scroll-wrapper",
          style: { left: _vm.left + "px" },
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }