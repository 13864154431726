import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.to-string";
import _typeof from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/typeof.js";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/root/workspace/crm_web_5uHk/node_modules/_core-js@2.6.12@core-js/modules/es6.array.iterator.js";
import "/root/workspace/crm_web_5uHk/node_modules/_core-js@2.6.12@core-js/modules/es6.promise.js";
import "/root/workspace/crm_web_5uHk/node_modules/_core-js@2.6.12@core-js/modules/es6.object.assign.js";
import "/root/workspace/crm_web_5uHk/node_modules/_core-js@2.6.12@core-js/modules/es7.promise.finally.js";
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import Vue from 'vue';
import axios from 'axios';
Vue.prototype.$axios = axios;
Vue.prototype.$axios = axios;
axios.defaults.baseURL = '/api';
import "./directives";
import VCharts from 'v-charts';
import ECharts from 'echarts';
import ElementUI from 'element-ui';
import Video from 'video.js';
import 'video.js/dist/video-js.css';
// import moment from 'moment'
import "./styles/element-variables.scss";
import '@/styles/index.scss'; // global css
import '@/styles/normalize.css'; // global css
import '@/styles/zxx.lib.css';
import contentmenu from 'v-contextmenu'; // 右键菜单
import 'v-contextmenu/dist/index.css';
import "./errorLog"; // error log
import '@/assets/library/font-awesome-4.7.0/css/font-awesome.min.css';
import App from "./App";
import router from "./router";
import store from "./store";
import drag from '@/utils/directives';
import _i18n from "./lang"; // Internationalization
import { global } from '@/global/global';
import { loadStyle } from "./utils/util";
// import { iconfontUrl, iconfontVersion } from '@/config/env';
// import '@/icons'; // icon
import permission from '@/directive/permission/index.js';
import '@/permission'; // permission control
import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';
import * as filters from "./filters"; // global filters
// css
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets
import '@/styles/index.scss'; // global css
import 'element-ui/lib/theme-chalk/index.css';
import directive from '@/directive/permission/permission';
import getTimeInterval from '@/utils/timeScreening';
import locale from 'element-ui/lib/locale/lang/zh-CN';
import { sendmsg } from '@/api/login';
// import '@/assets/css/iconfont/1.0.0/iconfont.css'; /* icofont*/
ElementUI.Dialog.props.closeOnClickModal.default = false;

// import locale from 'element-ui/lib/locale/lang/en' // lang i18n
Vue.prototype.baseURLs = 'https://gewawe.yangongzi.cn/';
import common from '@/static/common_js/common.js'; //版本号文件

Vue.prototype.getTimeInterval = getTimeInterval;
Vue.prototype.$video = Video;
// Vue.use(directives)

Vue.use(common);
Vue.use(permission);
Vue.use(Avue);

// register global utility filters.
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.use(VCharts);
Vue.prototype.$echarts = ECharts;
Vue.use(contentmenu);
Vue.use(ElementUI, {
  locale: locale
});
// iconfontVersion.forEach(ele => {
//     loadStyle(iconfontUrl.replace('$key', ele));
// });
Vue.use(ElementUI, {
  size: 'medium',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});
Vue.use(directive);
import pagination from '@/components/pageNation';
Vue.component('pagination', pagination);
import system from '@/components/systemTutorial';
Vue.component('system', system);
// 加载用户主题
if (localStorage.getItem('themeValue')) {
  global.changeTheme(localStorage.getItem('themeValue'));
} else {
  global.changeTheme('default');
}
/**
 * 根据学员级别ID返回名称
 * @param {string} id
 * @param {Array} Options structureOptions
 * @returns {Object} { name: [], id: [] }
 * @example see @/views/Faculty/Faculty.vue
 */
Vue.prototype.getFathersByid = function (id, data) {
  var prop = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'id';
  var arrRes = [];
  var _rev = function rev(data, nodeId) {
    for (var i = 0, length = data.length; i < length; i++) {
      var node = data[i];
      if (node[prop] === nodeId) {
        arrRes.unshift(node[prop]);
        return true;
      } else {
        if (node.children && node.children.length) {
          if (_rev(node.children, nodeId)) {
            arrRes.unshift(node[prop]);
            return true;
          }
        }
      }
    }
    return false;
  };
  _rev(data, id);
  return arrRes;
};
/**
 * 根据组织部门ID返回名称
 * @param {string} id
 * @param {Array} Options structureOptions
 * @returns {Object} { name: [], id: [] }
 * @example see @/views/Faculty/Faculty.vue
 */
Vue.prototype.getDepartmentNameById = function (id, Options) {
  function treeFindPath(Options, id) {
    var path = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
      name: [],
      id: []
    };
    if (!Options) return {
      name: [],
      id: []
    };
    var _iterator = _createForOfIteratorHelper(Options),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var data = _step.value;
        path.name.push(data.label);
        path.id.push(data.id);
        if (data.id == id) return path;
        if (data.children) {
          var findChildren = treeFindPath(data.children, id, path);
          if (findChildren.name.length) return findChildren;
        }
        path.name.pop();
        path.id.pop();
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    return {
      name: [],
      id: []
    };
  }
  var result = treeFindPath(Options, id);
  return result;
};
/**
 * 根据组织部门ID返回名称
 * @param {string} suid
 * @param {Array} Options structureOptions
 * @returns {Object} { name: [], id: [] }
 * @example see @/views/Faculty/Faculty.vue
 */
Vue.prototype.getDepartmentNameBySuid = function (id, Options) {
  function treeFindPath(Options, id) {
    var path = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
      name: [],
      suid: []
    };
    if (!Options) return {
      name: [],
      suid: []
    };
    var _iterator2 = _createForOfIteratorHelper(Options),
      _step2;
    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var data = _step2.value;
        path.name.push(data.label);
        path.suid.push(data.suid);
        if (data.suid == id) return path;
        if (data.children) {
          var findChildren = treeFindPath(data.children, id, path);
          if (findChildren.name.length) return findChildren;
        }
        path.name.pop();
        path.suid.pop();
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
    return {
      name: [],
      suid: []
    };
  }
  var result = treeFindPath(Options, id);
  return result;
};
/**
 * JSON字段为空时删除空字段、空对象、undefined、null、NaN
 * @param {string} suid
 * @param {Array} Options 
 * @returns {Object} 
 */
Vue.prototype.deleteEmptyProperty = function (obj) {
  var object = obj;
  for (var i in object) {
    var value = object[i];
    if (_typeof(value) === 'object') {
      if (Array.isArray(value)) {
        if (value.length == 0) {
          delete object[i];
          continue;
        }
      } else {
        delete object[i];
      }
    } else if (typeof value === 'number') {
      value = value.toString();
      if (value === 'NaN') {
        delete object[i];
      }
    } else if (value === '' || value === null || value === undefined) {
      delete object[i];
    }
  }
  return object;
};
/**
 * 获取当前年月日时分秒
 * @param {string} suid
 * @param {Array} Options 
 * @returns {Object} 
 */
Vue.prototype.currentTime = function () {
  var yy = new Date().getFullYear();
  var mm = new Date().getMonth() + 1;
  var dd = new Date().getDate();
  var hh = new Date().getHours();
  var mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
  var ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
  var times = yy + '' + mm + '' + dd + '' + hh + '' + mf + '' + ss;
  return times;
};
// /**
//  * 链接socket
//  * @param {string} ids
//  */
//  Vue.prototype.socketplan = function (ids) {

// }
new Vue({
  el: '#app',
  router: router,
  store: store,
  i18n: _i18n,
  // template: '<App/>',
  // components: { App }
  render: function render(h) {
    return h(App);
  }
});