import Vue from 'vue';
Vue.directive('loadmore', {
  bind: function bind(el, binding) {
    // 获取element-ui定义好的scroll盒子
    var SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
    SELECTWRAP_DOM.addEventListener('scroll', function () {
      var CONDITION = this.scrollHeight - this.scrollTop <= this.clientHeight;
      if (CONDITION) {
        binding.value();
      }
    });
  }
});