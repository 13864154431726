import "core-js/modules/es6.number.constructor";
import _typeof from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/typeof.js";
import "core-js/modules/es6.function.name";
import { validatenull } from '@/utils/validate';
/**
 * 存储localStorage
 */
export var setStore = function setStore(params) {
  var name = params.name,
    content = params.content,
    type = params.type;
  var obj = {
    dataType: _typeof(content),
    content: content,
    type: type,
    datetime: new Date().getTime()
  };
  if (type) window.sessionStorage.setItem(name, JSON.stringify(obj));else window.localStorage.setItem(name, JSON.stringify(obj));
};
/**
 * 获取localStorage
 */
export var getStore = function getStore(params) {
  var name = params.name;
  var obj = {};
  var content;
  obj = window.localStorage.getItem(name);
  if (validatenull(obj)) obj = window.sessionStorage.getItem(name);
  if (validatenull(obj)) return;
  obj = JSON.parse(obj);
  if (obj.dataType === 'string') {
    content = obj.content;
  } else if (obj.dataType === 'number') {
    content = Number(obj.content);
  } else if (obj.dataType === 'boolean') {
    /* eslint-disable */
    content = eval(obj.content);
  } else if (obj.dataType === 'object') {
    content = obj.content;
  }
  return content;
};
/**
 * 删除localStorage
 */
export var removeStore = function removeStore(params) {
  var name = params.name;
  window.localStorage.removeItem(name);
  window.sessionStorage.removeItem(name);
};

/**
 * 获取全部localStorage
 */
export var getAllStore = function getAllStore(params) {
  var list = [];
  var type = params.type;
  for (var i = 1; i <= window.sessionStorage.length; i++) {
    if (type) {
      list.push({
        name: window.sessionStorage.key(i),
        content: getStore({
          name: window.sessionStorage.key(i),
          type: 'session'
        })
      });
    } else {
      list.push(getStore({
        name: window.localStorage.key(i),
        content: getStore({
          name: window.localStorage.key(i)
        })
      }));
    }
  }
  return list;
};

/**
 * 清空全部localStorage
 */
export var clearStore = function clearStore(params) {
  var type = params.type;
  if (type) {
    window.sessionStorage.clear();
    return;
  }
  window.localStorage.clear();
};