import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Navbar, Sidebar, AppMain, TagsView } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapGetters } from 'vuex';
import bus from "./mixin/bus";
import { layoutRead, sendmsg } from '@/api/login';
import store from '@/store';
import '@/utils';
var delay = function () {
  var timer = 0;
  return function (callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
}();
export default {
  name: 'layout',
  data: function data() {
    return {
      sidebaritemData: {},
      activeNameEs: '',
      firstFlag: true,
      newsCliEs: 0,
      updateComponents: true
    };
  },
  components: {
    Navbar: Navbar,
    Sidebar: Sidebar,
    AppMain: AppMain,
    TagsView: TagsView
  },
  mixins: [ResizeMixin],
  computed: _objectSpread(_objectSpread({}, mapGetters(['permission_routers'])), {}, {
    sidebar: function sidebar() {
      return this.$store.state.app.sidebar;
    },
    device: function device() {
      return this.$store.state.app.device;
    },
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    },
    userId: function userId() {
      return this.$store.state.user.id;
    }
  }),
  created: function created() {
    bus.$on('collapse-content', function (msg) {});
  },
  mounted: function mounted() {
    var _this = this;
    var baseUrl = process.env.VUE_APP_BASE_API3;
    var socket = io(baseUrl);
    socket.on('connect', function () {
      socket.emit('login', _this.userId);
    });
  },
  methods: {
    activeName: function activeName(e) {
      this.activeNameEs = String(e);
    },
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch('CloseSideBar', {
        withoutAnimation: false
      });
    },
    myFun: function myFun(e) {
      this.sidebaritemData = e;
    },
    update: function update() {
      var _this2 = this;
      if (this.$route.path == '/BasicOperation/customerManagement/callCenter') {
        // this.$forceUpdate();
        // 移除组件
        this.updateComponents = false;
        // 在组件移除后，重新渲染组件
        // this.$nextTick可实现在DOM 状态更新后，执行传入的方法。
        this.$nextTick(function () {
          _this2.updateComponents = true;
        });
      }
    }
  }
};