import website from '@/const/website';
var common = {
  state: {
    website: website,
    sidebarltem_arr: null,
    active_index: null
  },
  mutations: {
    SET_SIDEBAR: function SET_SIDEBAR(state, arr) {
      state.sidebarltem_arr = arr;
    },
    SET_AINDEX: function SET_AINDEX(state, index) {
      state.active_index = index;
    }
  },
  actions: {
    sidebarltem: function sidebarltem(_ref, arr) {
      var commit = _ref.commit;
      commit('SET_SIDEBAR', arr);
    },
    activeIndex: function activeIndex(_ref2, index) {
      var commit = _ref2.commit;
      commit('SET_AINDEX', index);
    }
  }
};
export default common;