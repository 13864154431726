/*
 * @Author: your name
 * @Date: 2021-02-02 17:02:07
 * @LastEditTime: 2021-03-16 13:52:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web_bcrm\src\api\login.js
 */
import request from '@/utils/request';
export function login(username, password) {
  return request({
    url: '/clientserver.php/login/login',
    method: 'post',
    data: {
      username: username,
      password: password
    }
  });
}
export function getInfo() {
  return request({
    url: '/clientserver.php/User/userInfo',
    method: 'post'
  });
}
export function getQiYuIpcc(staffName) {
  return request({
    url: '/clientserver.php/call//login',
    method: 'get',
    params: {
      staff_name: staffName
    }
  });
}
export function logout() {
  return request({
    url: '/clientserver.php/User/logout',
    method: 'post'
  });
}

//zml消息通知已读
export function layoutRead(data) {
  return request({
    url: '/clientserver.php/Msg/update    ',
    method: 'post',
    data: data
  });
}
//登录成功后请求的消息zml

export function sendmsg() {
  return request({
    url: '/clientserver.php/Msg/sendmsg',
    method: 'post'
  });
}
export function billcustomer(data) {
  return request({
    url: '/clientserver.php/AllCustomer/billcustomer',
    method: 'post',
    data: data
  });
}