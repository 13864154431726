var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "logo" },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.isCollapse
          ? _c(
              "span",
              {
                key: "0",
                staticClass: "logo_title is-bold ",
                class: { "is-text": !_vm.type, "is-img": _vm.type },
              },
              [
                _vm.type
                  ? [
                      _c("img", {
                        attrs: {
                          src: _vm.website.logo,
                          width: "40",
                          height: "40",
                        },
                      }),
                    ]
                  : [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.website.logo) +
                          "\n            "
                      ),
                    ],
              ],
              2
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "transition-group",
        { attrs: { name: "fade" } },
        [
          !_vm.isCollapse
            ? [
                _c("span", { key: "1", staticClass: "logo_title is-bold" }, [
                  _vm._v("\n                CRM\n            "),
                ]),
                _vm._v(" "),
                _c("span", { key: "2", staticClass: "logo_subtitle" }, [
                  _vm._v(_vm._s(_vm.website.author)),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }