import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.split";
import store from "@/store";
import router from "@/router/index";
export default {
  inserted: function inserted(el, binding, vnode) {
    var nameOne = "";
    var nameTwo = "";
    var nameThree = "";
    var nameFour = "";
    var value = binding.value;
    var arr = router.currentRoute.fullPath.split("/");
    var roles = [];
    var roles3 = [];
    var roles4 = [];
    var AllJurisdiction = [];
    var Atter = [];
    var jsonId = [];
    var statistics = [];
    router.options.routes.forEach(function (res) {
      if (res.path == "/" + arr[1]) {
        nameOne = res.meta.title;
        if (res.children && res.children.length > 0) {
          res.children.forEach(function (item) {
            arr[2] = arr[2].split("?").length > 1 ? arr[2].split("?")[0] : arr[2];
            if (item.path == arr[2]) {
              nameTwo = item.meta.title;
              if (item.children && item.children.length > 0) {
                item.children.forEach(function (elent) {
                  arr[3] = arr[3].split("?").length > 1 ? arr[3].split("?")[0] : arr[3];
                  if (elent.path == arr[3]) {
                    nameThree = elent.meta.title;
                    if (elent.children && elent.children.length > 0) {
                      elent.children.forEach(function (events) {
                        arr[4] = arr[4].split("?").length > 1 ? arr[4].split("?")[0] : arr[4];
                        if (events.path == arr[4]) {
                          nameFour = events.meta.title;
                          return;
                        }
                      });
                    }
                    return;
                  }
                });
              }
              return;
            }
          });
        }
      }
    });
    //通过name字段查找按钮
    // console.log(store.getters.routersData);
    store.getters.routersData.forEach(function (res) {
      if (res.title == '系统教程') {
        statistics = [];
        statistics.push(res.children);
        statistics = statistics.flat();
      }
      if (res.meta.title == nameOne) {
        if (res.children && res.children.length > 0) {
          AllJurisdiction = [];
          res.children.forEach(function (item) {
            if (item.meta.title == nameTwo && item.children) {
              roles = item.children;
              AllJurisdiction.push(roles);
              item.children.forEach(function (elent) {
                if (elent.meta.title == nameThree && elent.children) {
                  roles3 = elent.children;
                  AllJurisdiction.push(roles3);
                  elent.children.forEach(function (event) {
                    if (event.meta.title == nameFour && event.children) {
                      roles4 = event.children;
                      AllJurisdiction.push(roles4);
                      return;
                    }
                  });
                }
              });
              return;
            }
          });
        }
      }
    });
    if (value && value instanceof Array && value.length > 0) {
      var permissionRoles = value;
      for (var res in roles) {
        if (roles[res].children == undefined) {
          if (roles != undefined && roles.length > 0) {
            var hasPermission = roles.some(function (role) {
              return permissionRoles.includes(role.meta.title);
            });
            if (!hasPermission && roles3.length <= 0 && roles4.length <= 0) {
              el.parentNode && el.parentNode.removeChild(el);
            }
          }
        }
      }
      for (var item in roles3) {
        if (roles3[item].children == undefined) {
          if (roles3 != undefined && roles3.length > 0) {
            var _hasPermission = roles3.some(function (role) {
              return permissionRoles.includes(role.meta.title);
            });
            if (!_hasPermission && roles4.length <= 0) {
              el.parentNode && el.parentNode.removeChild(el);
            }
          }
        }
      }
      if (roles4 != undefined && roles4.length > 0) {
        var _hasPermission2 = roles4.some(function (role) {
          return permissionRoles.includes(role.meta.title);
        });
        if (!_hasPermission2) {
          el.parentNode && el.parentNode.removeChild(el);
        }
      }
    } else {
      throw new Error("need roles! Like v-permission=\"['admin','editor']\"");
    }
    if (AllJurisdiction.length == 1) {
      AllJurisdiction[0].forEach(function (item) {
        Atter.push(item.title);
      });
      jsonId = AllJurisdiction[0];
    } else if (AllJurisdiction.length == 2) {
      AllJurisdiction[1].forEach(function (item) {
        Atter.push(item.title);
      });
      jsonId = AllJurisdiction[1];
    } else if (AllJurisdiction.length == 3) {
      AllJurisdiction[2].forEach(function (item) {
        Atter.push(item.title);
      });
      jsonId = AllJurisdiction[2];
    }
    store.dispatch('Atter', Atter);
    store.dispatch('JsonArr1', jsonId);
    store.dispatch('statistics', statistics);
  }
};