import request from '@/utils/request';
export function AuthRule() {
  //zzy菜单列表树形结构
  return request({
    url: '/clientserver.php/AuthRule/index',
    method: 'get'
  });
}
export function AuthRuleRuleList() {
  //zzy岗位菜单列表树形结构
  return request({
    url: '/clientserver.php/AuthRule/ruleList',
    method: 'get'
  });
}
export function Authsave(data) {
  //zzy新增菜单
  return request({
    url: '/clientserver.php/AuthRule/save',
    method: 'post',
    data: data
  });
}
//ll
export function Authread(data) {
  //zzy读取菜单信息
  return request({
    url: '/clientserver.php/AuthRule/read',
    method: 'post',
    data: data
  });
}
export function Authupdate(data) {
  //zzy编辑菜单信息
  return request({
    url: '/clientserver.php/AuthRule/update',
    method: 'post',
    data: data
  });
}
export function Authdelete(data) {
  //zzy删除菜单
  return request({
    url: '/clientserver.php/AuthRule/delete',
    method: 'post',
    data: data
  });
}
// 外呼管理
export function CallIndex(data) {
  //zzy列表
  return request({
    url: '/clientserver.php/Call/index',
    method: 'post',
    data: data
  });
}
export function CallAdd(data) {
  //zzy添加、编辑外呼账号
  return request({
    url: '/clientserver.php/Call/add',
    method: 'post',
    data: data
  });
}
export function CallDelete(data) {
  //zzy删除外呼账号
  return request({
    url: '/clientserver.php/Call/delete',
    method: 'post',
    data: data
  });
}
export function CallBinding(data) {
  //zzy绑定外呼账号
  return request({
    url: '/clientserver.php/Call/binding',
    method: 'post',
    data: data
  });
}
// 外呼管理
export function CallTrIndex(data) {
  //列表
  return request({
    url: '/clientserver.php/CallTianRun/index',
    method: 'post',
    data: data
  });
}
export function CallTrBinding(data) {
  //绑定
  return request({
    url: '/clientserver.php/CallTianRun/binding',
    method: 'post',
    data: data
  });
}
export function CallTrUnBinding(data) {
  //解绑
  return request({
    url: '/clientserver.php/CallTianRun/unbundling',
    method: 'post',
    data: data
  });
}
export function CallTrDelete(data) {
  //删除
  return request({
    url: '/clientserver.php/CallTianRun/delete',
    method: 'post',
    data: data
  });
}
export function CallTrSynchronization(data) {
  //同步
  return request({
    url: '/clientserver.php/CallTianRun/synchronizationTianRanClientsList',
    method: 'post',
    data: data
  });
}
// 人员手机配置
export function CallPhoneIndex(data) {
  //列表
  return request({
    url: '/clientserver.php/CallPhone/index',
    method: 'post',
    data: data
  });
}
export function CallPhoneBinding(data) {
  //绑定
  return request({
    url: '/clientserver.php/CallPhone/binding',
    method: 'post',
    data: data
  });
}
export function CallPhoneUnbundling(data) {
  //解绑
  return request({
    url: '/clientserver.php/CallPhone/unbundling',
    method: 'post',
    data: data
  });
}
export function synchronizationCallPhoneList(data) {
  //解绑
  return request({
    url: '/clientserver.php/CallPhone/synchronizationCallPhoneList',
    method: 'post',
    data: data
  });
}
export function checkCustomerByMobile(data) {
  //检测手机号是否在私海
  return request({
    url: '/clientserver.php/Common/checkCustomerByMobile',
    method: 'post',
    data: data
  });
}
// 审批流程配置
export function ConfigurationIndex(data) {
  return request({
    url: '/clientserver.php/ApprovalProcessConfiguration/index',
    method: 'post',
    data: data
  });
}
export function ConfigurationSave(data) {
  return request({
    url: '/clientserver.php/ApprovalProcessConfiguration/save',
    method: 'post',
    data: data
  });
}
export function ConfigurationDelete(data) {
  return request({
    url: '/clientserver.php/ApprovalProcessConfiguration/delete',
    method: 'post',
    data: data
  });
}
export function ConfigurationRead(data) {
  return request({
    url: '/clientserver.php/ApprovalProcessConfiguration/read',
    method: 'post',
    data: data
  });
}