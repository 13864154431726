var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pages" },
    [
      _c("el-pagination", {
        staticClass: "pagination",
        attrs: {
          "current-page": _vm.currentPage,
          "page-size": _vm.pageSize,
          "page-sizes": _vm.pageSizes,
          layout: "total, sizes, prev, pager, next, jumper",
          background: "",
          total: _vm.totalPage,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }