import Vue from 'vue';
import Vuex from 'vuex';
import app from "./modules/app";
import user from "./modules/user";
import common from "./modules/common";
import getters from "./getters";
import fullScreen from "./modules/fullScreen";
import permission from "./modules/permission";
import tagsView from "./modules/tagsView";
import errorLog from "./modules/errorLog";
Vue.use(Vuex);
var store = new Vuex.Store({
  modules: {
    app: app,
    user: user,
    common: common,
    fullScreen: fullScreen,
    permission: permission,
    tagsView: tagsView,
    errorLog: errorLog
  },
  getters: getters
});
export default store;