import Cookies from 'js-cookie';
var TokenKey = 'token';
export function getTokens() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}
var Msgidkey = 'Msgid';
export function getmsgid() {
  return Cookies.get(Msgidkey);
}
export function setmsgid(stemsgid) {
  return Cookies.set(Msgidkey, stemsgid);
}