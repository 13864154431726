import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.to-string";
import _toConsumableArray from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import _slicedToArray from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/slicedToArray.js";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.function.name";
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var tagsView = {
  state: {
    visitedViews: [],
    cachedViews: []
  },
  mutations: {
    ADD_VISITED_VIEWS: function ADD_VISITED_VIEWS(state, view) {
      if (state.visitedViews.some(function (v) {
        return v.path === view.fullPath;
      })) return;
      state.visitedViews.push({
        name: view.name,
        path: view.fullPath,
        title: view.meta.title || 'no-name'
      });
      if (!view.meta.noCache) {
        state.cachedViews.push(view.name);
      }
    },
    DEL_VISITED_VIEWS: function DEL_VISITED_VIEWS(state, view) {
      var _iterator = _createForOfIteratorHelper(state.visitedViews.entries()),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _step$value = _slicedToArray(_step.value, 2),
            i = _step$value[0],
            v = _step$value[1];
          if (v.path === view.path) {
            state.visitedViews.splice(i, 1);
            break;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      var _iterator2 = _createForOfIteratorHelper(state.cachedViews),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var _i = _step2.value;
          if (_i === view.name) {
            var index = state.cachedViews.indexOf(_i);
            state.cachedViews.splice(index, 1);
            break;
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    },
    DEL_OTHERS_VIEWS: function DEL_OTHERS_VIEWS(state, view) {
      var _iterator3 = _createForOfIteratorHelper(state.visitedViews.entries()),
        _step3;
      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var _step3$value = _slicedToArray(_step3.value, 2),
            i = _step3$value[0],
            v = _step3$value[1];
          if (v.path === view.path) {
            state.visitedViews = state.visitedViews.slice(i, i + 1);
            break;
          }
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }
      var _iterator4 = _createForOfIteratorHelper(state.cachedViews),
        _step4;
      try {
        for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
          var _i2 = _step4.value;
          if (_i2 === view.name) {
            var index = state.cachedViews.indexOf(_i2);
            state.cachedViews = state.cachedViews.slice(index, _i2 + 1);
            break;
          }
        }
      } catch (err) {
        _iterator4.e(err);
      } finally {
        _iterator4.f();
      }
    },
    DEL_ALL_VIEWS: function DEL_ALL_VIEWS(state) {
      state.visitedViews = [];
      state.cachedViews = [];
    }
  },
  actions: {
    addVisitedViews: function addVisitedViews(_ref, view) {
      var commit = _ref.commit;
      commit('ADD_VISITED_VIEWS', view);
    },
    delVisitedViews: function delVisitedViews(_ref2, view) {
      var commit = _ref2.commit,
        state = _ref2.state;
      return new Promise(function (resolve) {
        commit('DEL_VISITED_VIEWS', view);
        resolve(_toConsumableArray(state.visitedViews));
      });
    },
    delOthersViews: function delOthersViews(_ref3, view) {
      var commit = _ref3.commit,
        state = _ref3.state;
      return new Promise(function (resolve) {
        commit('DEL_OTHERS_VIEWS', view);
        resolve(_toConsumableArray(state.visitedViews));
        console.log(_toConsumableArray(state.visitedViews), '[...state.visitedViews]');
      });
    },
    delAllViews: function delAllViews(_ref4) {
      var commit = _ref4.commit,
        state = _ref4.state;
      return new Promise(function (resolve) {
        commit('DEL_ALL_VIEWS');
        resolve(_toConsumableArray(state.visitedViews));
      });
    }
  }
};
export default tagsView;