import { asyncRouterMap, constantRouterMap, options } from '@/router';
import { AuthRule } from "../../api/updateUserInfo/users";
import router from "../../router";
/**
 * 通过meta.role判断是否与当前用户权限匹配
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.indexOf(role) >= 0;
    });
  } else {
    return true;
  }
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param asyncRouterMap
 * @param roles
 */
function filterAsyncRouter(asyncRouterMap, roles) {
  var accessedRouters = asyncRouterMap.filter(function (route) {
    if (hasPermission(roles, route)) {
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, roles);
      }
      return true;
    }
    return false;
  });
  return accessedRouters;
}
function filterMenus(asyncRouterMap, remoteRouterMap) {
  var res = [];
  asyncRouterMap.map(function (async) {
    remoteRouterMap.map(function (remote) {
      if (remote.path === async.path) {
        // asyncRouterMap.map(res=>{
        async.id = remote.id;
        // })
        async.meta.roles = remote.meta.roles;
        if (async.children && remote.children) {
          async.children = filterMenus(async.children, remote.children);
        }
        res.push(async);
      }
    });
  });
  return res;
}
var permission = {
  state: {
    routers: constantRouterMap,
    addRouters: [],
    routersdata: [] //权限路由
  },
  mutations: {
    SET_ROUTERS: function SET_ROUTERS(state, routers) {
      state.addRouters = routers;
      state.routers = constantRouterMap.concat(routers);
      router.options.routes = state.routers; //合并
    },
    //state存储权限路由
    SET_ROUTERSDATA: function SET_ROUTERSDATA(state, routersdata) {
      state.routersdata = routersdata;
    }
  },
  actions: {
    GenerateRoutes: function GenerateRoutes(_ref, data) {
      var commit = _ref.commit;
      return new Promise(function (resolve) {
        AuthRule().then(function (res) {
          var roles = data.roles;
          var remoteRouterMap = res.data;
          var accessedRouters;
          if (roles.indexOf('admin') >= 0) {
            accessedRouters = asyncRouterMap;
          } else {
            accessedRouters = filterAsyncRouter(filterMenus(asyncRouterMap, remoteRouterMap), roles);
          }
          commit('SET_ROUTERSDATA', remoteRouterMap); //vuex存储权限路由
          commit('SET_ROUTERS', accessedRouters);
          resolve();
        });
      });
    }
  }
};
export default permission;