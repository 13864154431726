var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tags-view-container" },
    [
      _c(
        "nx-scroll-pane",
        { ref: "scrollPane", staticClass: "tags-view-wrapper" },
        _vm._l(Array.from(_vm.visitedViews), function (tag) {
          return _c(
            "router-link",
            {
              key: tag.path,
              ref: "tag",
              refInFor: true,
              staticClass: "tags-view-item",
              class: _vm.isActive(tag) ? "active" : "",
              attrs: { to: tag.path },
              nativeOn: {
                contextmenu: function ($event) {
                  $event.preventDefault()
                  return _vm.openMenu(tag, $event)
                },
              },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.generateTitle(tag.title)) + "\n      "
              ),
              _c("span", {
                staticClass: "el-icon-close",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.closeSelectedTag(tag)
                  },
                },
              }),
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible",
            },
          ],
          staticClass: "contextmenu",
          style: { left: _vm.left + "px", top: _vm.top + "px" },
        },
        [
          _c(
            "li",
            {
              on: {
                click: function ($event) {
                  return _vm.closeSelectedTag(_vm.selectedTag)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("tagsView.close")))]
          ),
          _vm._v(" "),
          _c("li", { on: { click: _vm.closeOthersTags } }, [
            _vm._v(_vm._s(_vm.$t("tagsView.closeOthers"))),
          ]),
          _vm._v(" "),
          _c("li", { on: { click: _vm.closeAllTags } }, [
            _vm._v(_vm._s(_vm.$t("tagsView.closeAll"))),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }