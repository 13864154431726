import "core-js/modules/es6.function.name";
/*
 * @Author: your name
 * @Date: 2021-04-01 11:06:35
 * @LastEditTime: 2021-04-27 15:53:42
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web_bcrm\src\store\getters.js
 */
var getters = {
  language: function language(state) {
    return state.app.language;
  },
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.user.token;
  },
  Atter: function Atter(state) {
    return state.user.Atter;
  },
  JsonArr1: function JsonArr1(state) {
    return state.user.JsonArr1;
  },
  StatisTics: function StatisTics(state) {
    return state.user.StatisTics;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  id: function id(state) {
    return state.user.id;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  callname: function callname(state) {
    return state.user.callname;
  },
  callpass: function callpass(state) {
    return state.user.callpass;
  },
  isShi: function isShi(state) {
    return state.user.isShi;
  },
  TianRun: function TianRun(state) {
    return state.user.TianRun;
  },
  //zml

  realname: function realname(state) {
    return state.user.realname;
  },
  delivery: function delivery(state) {
    return state.user.delivery;
  },
  structure_name: function structure_name(state) {
    return state.user.structure_name;
  },
  group_title: function group_title(state) {
    return state.user.group_title;
  },
  username: function username(state) {
    return state.user.username;
  },
  mobile: function mobile(state) {
    return state.user.mobile;
  },
  hiredate: function hiredate(state) {
    return state.user.hiredate;
  },
  positive_date: function positive_date(state) {
    return state.user.positive_date;
  },
  call: function call(state) {
    return state.user.call;
  },
  Msgid: function Msgid(state) {
    return state.user.Msgid;
  },
  //
  routersData: function routersData(state) {
    return state.permission.routersdata;
  },
  permission_routers: function permission_routers(state) {
    return state.permission.routers;
  },
  addRouters: function addRouters(state) {
    return state.permission.addRouters;
  },
  website: function website(state) {
    return state.common.website;
  },
  isLock: function isLock(state) {
    return state.user.isLock;
  },
  lockPasswd: function lockPasswd(state) {
    return state.user.lockPasswd;
  },
  isFullScren: function isFullScren(state) {
    return state.common.isFullScren;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  browserHeaderTitle: function browserHeaderTitle(state) {
    return state.user.browserHeaderTitle;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  },
  sidebarltem_arr: function sidebarltem_arr(state) {
    return state.common.sidebarltem_arr;
  },
  active_index: function active_index(state) {
    return state.common.active_index;
  },
  // 未读消息
  unread_message: function unread_message(state) {
    return state.user.unread_message;
  }
};
export default getters;