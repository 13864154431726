var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu-wrapper" },
    [
      _vm.staticId == 1
        ? _c(
            "el-menu",
            {
              staticClass: "sidebar-el-menu",
              attrs: {
                "default-active": this.$route.path,
                "default-openeds": _vm.defaultArr,
                collapse: _vm.isCollapse,
                "background-color": "#324157",
                "text-color": "#bfcbd9",
                "unique-opened": "",
              },
              on: { select: _vm.menuSelect },
            },
            [
              _vm._l(_vm.sidebaritem.children, function (item) {
                return [
                  item.children
                    ? [
                        _c(
                          "el-submenu",
                          {
                            key: item.index,
                            attrs: {
                              index: "/" + item.path,
                              route: { path: "/" + item.path },
                            },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _c(
                                "div",
                                {
                                  style:
                                    "/" + item.path == _vm.activeNames
                                      ? "background:#fff !important;color:#1a95ff;padding:0 20px;"
                                      : "padding:0 20px;",
                                },
                                [
                                  _c("i", { class: item.meta.icon }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { attrs: { slot: "title" }, slot: "title" },
                                    [_vm._v(_vm._s(item.meta.title))]
                                  ),
                                  _vm._v(" "),
                                  item.meta.title == "订单管理" &&
                                  _vm.$store.getters.unread_message
                                    .unread_total_quantity != "0"
                                    ? _c(
                                        "span",
                                        { staticClass: "spanFont fatherBig" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$store.getters.unread_message
                                                .unread_total_quantity
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.meta.title == "财务管理" &&
                                  _vm.$store.getters.unread_message
                                    .financial_audit_order_quantity != "0"
                                    ? _c(
                                        "span",
                                        { staticClass: "spanFont fatherBig" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$store.getters.unread_message
                                                .financial_audit_order_quantity
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _vm._l(item.children, function (subItem) {
                              return [
                                subItem.children
                                  ? _c(
                                      "el-submenu",
                                      {
                                        key: subItem.index,
                                        attrs: {
                                          route: {
                                            path:
                                              "/" +
                                              item.path +
                                              "/" +
                                              subItem.path,
                                          },
                                          index:
                                            "/" +
                                            item.path +
                                            "/" +
                                            subItem.path,
                                        },
                                      },
                                      [
                                        _c("template", { slot: "title" }, [
                                          _c(
                                            "div",
                                            {
                                              style:
                                                "/" +
                                                  item.path +
                                                  "/" +
                                                  subItem.path ==
                                                _vm.activeNames
                                                  ? "background:#fff !important;color:#1a95ff;padding:0 55px;"
                                                  : "padding:0 55px;",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(subItem.meta.title) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _vm._l(
                                          subItem.children,
                                          function (threeItem, i) {
                                            return _c(
                                              "el-menu-item",
                                              {
                                                key: i,
                                                staticStyle: { padding: "0px" },
                                                attrs: {
                                                  index:
                                                    "/" +
                                                    item.path +
                                                    "/" +
                                                    subItem.path +
                                                    "/" +
                                                    threeItem.path,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.Three(
                                                      item,
                                                      subItem,
                                                      threeItem
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    style:
                                                      "/" +
                                                        item.path +
                                                        "/" +
                                                        subItem.path +
                                                        "/" +
                                                        threeItem.path ==
                                                      _vm.activeNames
                                                        ? "background:#fff !important;color:#1a95ff;padding:0 75px;"
                                                        : "padding:0 75px;",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                    " +
                                                        _vm._s(
                                                          threeItem.meta.title
                                                        ) +
                                                        "\n                                "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  : _c(
                                      "el-menu-item",
                                      {
                                        key: subItem.index,
                                        staticStyle: { padding: "0px" },
                                        attrs: {
                                          index:
                                            "/" +
                                            item.path +
                                            "/" +
                                            subItem.path,
                                          route: {
                                            path:
                                              "/" +
                                              item.path +
                                              "/" +
                                              subItem.path,
                                          },
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.Three(item, subItem)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            style:
                                              "/" +
                                                item.path +
                                                "/" +
                                                subItem.path ==
                                              _vm.activeNames
                                                ? "background:#fff !important;color:#1a95ff;padding:0 55px;"
                                                : "padding:0 55px;",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(subItem.meta.title) +
                                                "\n                                "
                                            ),
                                            item.meta.title == "订单管理" &&
                                            subItem.meta.title ==
                                              "主管审核订单" &&
                                            _vm.$store.getters.unread_message
                                              .supervisor_audit_order_quantity !=
                                              "0"
                                              ? _c(
                                                  "span",
                                                  { staticClass: "spanFont" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$store.getters
                                                          .unread_message
                                                          .supervisor_audit_order_quantity
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            item.meta.title == "订单管理" &&
                                            subItem.meta.title ==
                                              "未通过订单" &&
                                            _vm.$store.getters.unread_message
                                              .supervisor_audit_reject_quantity !=
                                              "0"
                                              ? _c(
                                                  "span",
                                                  { staticClass: "spanFont" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$store.getters
                                                          .unread_message
                                                          .supervisor_audit_reject_quantity
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            item.meta.title == "订单管理" &&
                                            subItem.meta.title ==
                                              "已通过订单" &&
                                            _vm.$store.getters.unread_message
                                              .audit_success_quantity != "0"
                                              ? _c(
                                                  "span",
                                                  { staticClass: "spanFont" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$store.getters
                                                          .unread_message
                                                          .audit_success_quantity
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            item.meta.title == "财务管理" &&
                                            subItem.meta.title ==
                                              "待审核订单" &&
                                            _vm.$store.getters.unread_message
                                              .financial_audit_order_quantity !=
                                              "0"
                                              ? _c(
                                                  "span",
                                                  { staticClass: "spanFont" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$store.getters
                                                          .unread_message
                                                          .financial_audit_order_quantity
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                              ]
                            }),
                          ],
                          2
                        ),
                      ]
                    : [
                        _c(
                          "el-menu-item",
                          {
                            key: item.path,
                            attrs: { index: "/" + item.path },
                            on: {
                              click: function ($event) {
                                return _vm.Tow(item)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                style:
                                  "/" + item.path == _vm.activeNames
                                    ? "background:#fff !important; color:#1a95ff; padding:0 20px;"
                                    : "padding:0 20px;",
                              },
                              [
                                _c("i", {
                                  class: item.meta.icon,
                                  style:
                                    "/" + item.path == _vm.activeNames
                                      ? "color:#1a95ff;"
                                      : "color:#fff;",
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [_vm._v(_vm._s(item.meta.title))]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }