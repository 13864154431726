var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _vm.device === "mobile" && _vm.sidebar.opened
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("sidebar", {
        staticClass: "sidebar-container",
        attrs: {
          sidebaritemData: _vm.sidebaritemData,
          activeNameEs: _vm.activeNameEs,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-container" },
        [
          _c("navbar", {
            attrs: { routes: _vm.permission_routers },
            on: { myFun: _vm.myFun, activeName: _vm.activeName },
          }),
          _vm._v(" "),
          _vm.updateComponents ? _c("app-main") : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }