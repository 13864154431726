/*
 * @Author: your name
 * @Date: 2021-07-08 18:38:16
 * @LastEditTime: 2021-07-09 10:48:49
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \shangchengd:\CRM\src\api\ceshi_xiugai\order.js
 */
import request from '@/utils/request';
//新建成交订单
export function orderAdd(data) {
  return request({
    url: '/clientserver.php/CustomerOrder/add',
    method: 'post',
    data: data
  });
}
//编辑成交订单
export function orderEdit(data) {
  return request({
    url: '/clientserver.php/CustomerOrder/exit',
    method: 'post',
    data: data
  });
}
//获取学习专业
export function getStudyMajor(data) {
  return request({
    url: '/clientserver.php/Common/getStudyMajor',
    method: 'post',
    data: data
  });
}
//获取校园层次
export function getSchoolLevels(data) {
  return request({
    url: '/clientserver.php/Common/getSchoolLevels',
    method: 'post',
    data: data
  });
}
// 获取订单详情
export function orderRead(data) {
  return request({
    url: '/clientserver.php/CustomerOrder/read',
    method: 'post',
    data: data
  });
}
// 获取客户订单状态
export function getOrderStatus(data) {
  return request({
    url: '/clientserver.php/CustomerOrder/getOrderStatus',
    method: 'post',
    data: data
  });
}
// 审核订单
export function auditCustomerOrder(data) {
  return request({
    url: '/clientserver.php/CustomerOrder/auditCustomerOrder',
    method: 'post',
    data: data
  });
}
// 订单审核详情
export function getAuditDetail(data) {
  return request({
    url: '/clientserver.php/CustomerOrder/getAuditDetail',
    method: 'post',
    data: data
  });
}
// 取消订单
export function cancelCustomerOrder(data) {
  return request({
    url: '/clientserver.php/CustomerOrder/cancelCustomerOrder',
    method: 'post',
    data: data
  });
}
// 未阅读消息
export function getStatusData(data) {
  return request({
    url: '/clientserver.php/CustomerOrder/getOrderStatusDataQuantity',
    method: 'post',
    data: data
  });
}