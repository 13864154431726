import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//

export default {
  data: function data() {
    return {};
  },
  name: 'pagination',
  props: {
    'pageSizes': {
      type: Array,
      default: function _default() {
        return [10, 20, 30, 50, 100];
      }
    },
    'pageSize': {
      type: Number,
      default: 10
    },
    'totalPage': Number,
    'currentPage': Number
  },
  methods: {
    handleSizeChange: function handleSizeChange(e) {
      this.$emit('handleSizeChange', e);
    },
    handleCurrentChange: function handleCurrentChange(e) {
      this.$emit('handleCurrentChange', e);
    }
  }
};