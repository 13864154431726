import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.flags";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/web.dom.iterable";
import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.function.name";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import '@/utils/directives';
import { mapGetters } from 'vuex';
import ErrorLog from '@/components/ErrorLog';
import nxHamburger from '@/components/nx-hamburger';
import nxLangSelect from '@/components/nx-lang-select/index';
import store from '@/store';
import bus from '@/views/layout/mixin/bus';
import { sendmsg, billcustomer, getQiYuIpcc } from '@/api/login';
import { checkCustomerByMobile } from '@/api/updateUserInfo/users';
import { getStatusData } from '@/api/ceshi_xiugai/order';
export default {
  name: 'navBar',
  props: {
    routes: {
      type: Array
    }
  },
  components: {
    nxHamburger: nxHamburger,
    nxLangSelect: nxLangSelect,
    ErrorLog: ErrorLog
  },
  data: function data() {
    return {
      positionX: 0,
      positionY: 0,
      assa: '',
      Mantle: false,
      ketianyun: false,
      instance: '',
      tinetToolbarDom: '',
      tianrun1: false,
      ksjhag: false,
      activeIndex: '0',
      tinetOptions: {
        isAutoLogin: true,
        showHold: false,
        // 支持保持
        showConsult: false,
        //支持咨询,
        showTransfer: false,
        //支持转移
        showSatisfaction: false,
        //支持满意度
        showMute: true //支持静音`
      },
      calls: '',
      flags: 0,
      MobileAddress: '',
      customer_id: '',
      customer_data_id: '',
      dialogShow: false,
      callShow: false,
      initConfig: {
        inDeviceId: 'default',
        outDeviceId: 'default',
        baseUrl: '',
        username: '',
        password: '',
        stateEventListener: this.stateEventListener,
        enableWebRTC: 'false',
        popScreen: this.popScreen,
        iceCheckTimeout: 500,
        webRTCAutoAnswer: localStorage.autoAnswer ? localStorage.autoAnswer === 'true' : false,
        debug: false,
        register_expire: 600,
        httpErrorCallback: this.httpErrorCallback
      },
      addye: [],
      showNav: false,
      dialogVisible: false,
      newsEven: '',
      userName: this.$store.getters.name,
      addrouters: [],
      activeName: '',
      newsEs: '',
      isName: true,
      mobile: '',
      showEs: true,
      tableData: [],
      img: 'https://gewawe.yangongzi.cn/storage/client/direction/file/20210122/1ffe1ffcfa16db24f0b02b3203808e82.png'
    };
  },
  mounted: function mounted() {
    var _this2 = this;
    if (this.$route.query.course == 1) {
      this.showNav = false;
    } else {
      this.showNav = true;
      var aaa = localStorage.getItem('Clink');
      this.tianrun(aaa);
    }
    sendmsg().then(function (res) {
      _this2.newsEs = res.data.unread_num;
      localStorage.setItem('shuliang', _this2.newsEs);
    });
    setInterval(function () {
      var ketianyun = localStorage.getItem('state');
      if (ketianyun == 'BUSY') {
        _this2.ketianyun = true;
      } else {
        _this2.ketianyun = false;
      }
      if (_this2.newsEs != 0) {
        _this2.newsEs = Number(localStorage.getItem('shuliang'));
      }
    }, 1000);
  },
  created: function created() {
    var aaa = this.$router.options.routes.slice(4, this.$router.options.routes.length);
    var router_path = '/' + this.$router.history.current.path.split('/')[1];
    for (var i = 0; i < aaa.length; i++) {
      if (aaa[i].name != undefined) {
        this.addye.push(aaa[i]);
      }
    }
    var active_index = this.addye.map(function (res) {
      return res.path;
    }).indexOf(router_path);
    this.$store.dispatch('activeIndex', '' + active_index);
    this.addrouters = this.addye;
    this.activeIndex = this.$store.getters.active_index;
    if (localStorage.getItem('menuId') == '329') {
      localStorage.setItem('menuId', '329');
    } else if (localStorage.getItem('menuId') == '71') {
      localStorage.setItem('menuId', '71');
    } else if (localStorage.getItem('menuId') == '31') {
      localStorage.setItem('menuId', '31');
    } else if (localStorage.getItem('menuId') == '294') {
      localStorage.setItem('menuId', '294');
    }
    store.dispatch('sidebarltem', this.addrouters[this.activeIndex]);
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['sidebar', 'name', 'avatar', 'Msgid'])), {}, {
    Msgid: function Msgid() {
      return this.$store.getters.Msgid;
    }
  }),
  methods: {
    aaa: function aaa(e, i) {
      // getStatusData().then((res) => {
      //     localStorage.setItem('unreadMgs', JSON.stringify(res.data));
      // });
      this.$emit('myFun', e);
      store.dispatch('sidebarltem', e);
      this.$store.dispatch('activeIndex', i);
      this.$emit('activeName', this.activeName);
      if (e.name == '看板') {
        localStorage.setItem('menuId', '329');
      } else if (e.name == '基础操作') {
        localStorage.setItem('menuId', '71');
      } else if (e.name == '系统管理') {
        localStorage.setItem('menuId', '31');
      } else if (e.name == '管理分析') {
        localStorage.setItem('menuId', '294');
      }
    },
    hitTheBill: function hitTheBill() {
      var _this3 = this;
      if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
        this.$notify({
          title: '警告',
          message: '请输入正确手机号',
          type: 'warning',
          duration: 2000
        });
        this.Show = false;
      } else if (this.mobile == '') {
        this.$notify({
          title: '警告',
          message: '请输入手机号',
          type: 'warning',
          duration: 2000
        });
      } else {
        this.tableData = [];
        billcustomer({
          mobile: this.mobile
        }).then(function (respomse) {
          if (respomse.data !== '') {
            _this3.dialogVisible = true;
            var asas = respomse.data;
            asas.forEach(function (item) {
              item.lower_list.map(function (event) {
                if (event.sea_type == '0') {
                  event.sea_type = '私海';
                } else if (event.sea_type == '1') {
                  event.sea_type = '部门公海';
                } else if (event.sea_type == '2') {
                  event.sea_type = '部门二级公海';
                } else if (event.sea_type == '3') {
                  event.sea_type = '外送过项目方的数据';
                } else if (event.sea_type == '4') {
                  event.sea_type = '外送公海客户池';
                }
              });
            });
            _this3.tableData = asas;
          }
        });
      }
    },
    toggleSideBar: function toggleSideBar() {
      bus.$emit('collapse', this.sidebar.opened);
      this.$store.dispatch('ToggleSideBar');
    },
    logoutS: function logoutS() {
      this.$store.dispatch('LogOut').then(function () {
        location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
        localStorage.clear();
      });
    },
    //个人中心
    logone: function logone() {
      if (this.$route.query.course == 1) {
        this.$router.replace({
          path: '/profile?course=1'
        });
      } else {
        this.$router.replace({
          path: '/profile'
        });
      }
    },
    //系统教程
    SystemTutorial: function SystemTutorial() {
      var routeUrl = this.$router.resolve({
        path: '/homePage/hoemLock?course=1'
      });
      window.open(routeUrl.href, '_blank');
    },
    //历史登陆
    Historyup: function Historyup() {
      if (this.$route.query.course == 1) {
        this.$router.replace({
          path: '/homePage/Historyup?course=1'
        });
      } else {
        this.$router.replace({
          path: '/homePage/Historyup'
        });
      }
    },
    //消息管理
    goMessage: function goMessage() {
      this.$router.replace({
        name: '消息管理'
      });
    },
    handleSelect: function handleSelect(key, keyPath) {
      localStorage.setItem('NavBasics', key);
    },
    // 初始化网易七鱼sdk
    initQiyuSdk: function initQiyuSdk(url) {
      var sdk = document.createElement('script');
      sdk.async = !0;
      sdk.src = url;
      sdk.onload = function () {
        //sdk加载成功后，注册呼叫工具条事件
        qiConnect.on({
          onload: function onload() {
            //呼叫工具条加载完毕的事件通知。此事件完成后才可调用外呼接口
            console.log('呼叫工具条加载完毕！');
          },
          // 工具条初始化完成后，可以设置状态
          initSuccess: function initSuccess() {
            qiConnect.setStatus([qiConnect.statusOptions[0].value]);
          },
          /**
           * 建立新呼叫会话的事件，包括外呼和呼入
           * @param  {Object} session  呼叫会话的session信息
           *
           * @param  {String} session.address      号码归属地
           * @param  {String} session.usernumber   客户号码
           * @param  {Number} session.sessionid    会话id
           * @param  {String} session.staffnumber  坐席号码
           * @param  {Number} session.staffid      坐席id
           * @param  {String} session.staffname    坐席账号
           * @param  {Number} session.direction    会话方向，1表示呼入，2表示呼出
           */
          session: function session(_session) {
            window.isCalling = true;
            console.log('session', _session);
          },
          answered: function answered(data) {
            console.log('answered', data);
          },
          sessionClose: function sessionClose(data) {
            console.log('sessionClose', data);
          },
          byeFailed: function byeFailed(data) {
            // window.isCalling = false;
            alert('byeFailed 挂断异常：' + data.cause, data);
          },
          statusOptionsChanged: function statusOptionsChanged(data) {
            console.log('statusOptionsChanged', data);
          },
          statusChanged: function statusChanged(data) {
            console.log('statusChanged', data);

            // 离线
            if (['0'].includes(data[0])) {
              qiConnect.bye();
            }
          },
          statusChangeFailed: function statusChangeFailed(data) {
            console.log('statusChangeFailed', data);
          },
          overProcess: function overProcess(data) {
            window.isCalling = false;
            console.log('overProcess', data);
            qiConnect.keepPanelUnfold(false);
          }
        });
      };
      document.body.appendChild(sdk);
    },
    // zzy天润
    tianrun: function tianrun(val) {
      var _this4 = this;
      var _this = this;
      var params = {};
      var stose = store.getters.TianRun;
      if (stose.bindTel != '') {
        if (this.$route.query.course == 1) {
          this.ksjhag = false;
        } else {
          this.ksjhag = true;
        }
        // this.tinetToolbarDom = document.getElementById('tinetToolbar');
        // this.instance = new ClinkToolbar(this.tinetOptions, this.tinetToolbarDom, (e) => {});
        var params = {};
        params.identifier = stose.identifier; //编号
        params.cno = stose.bindTel; // 坐席号
        params.loginStatus = 1;
        params.bindTel = stose.bindTel; // 编号、手机号
        params.bindType = stose.bindType; // 类型
        params.password = stose.password; // 密码

        // 获取网易七鱼sdk
        getQiYuIpcc('zklm' + stose.cno).then(function (res) {
          console.log('res', res);
          _this4.initQiyuSdk(res.data.result.sdk_url);
          localStorage.setItem('sdk_url', res.data.result.sdk_url);
        });
        return;
        ClinkAgent.login(params);
        this.instance.userCustomEvent.on(ClinkAgent.EventType.STATUS, function (e) {
          localStorage.setItem('Clink', e.code);
          if (e.action == 'ringingIb') {
            _this4.callShow = true;
            localStorage.setItem('call', e.customerNumber);
            var orignalSetItem = localStorage.setItem;
            localStorage.setItem = function (key, newValue) {
              var setItemEvent = new Event('setItemEvent');
              setItemEvent.newValue = newValue;
              window.dispatchEvent(setItemEvent);
              orignalSetItem.apply(this, arguments);
            };
            window.addEventListener('setItemEvent', function (item) {
              // console.log('--------',item);
            });
            localStorage.setItem('Incoming', e.action);
            _this4.ByMobile(e.customerNumber);
          } else {
            _this4.callShow = false;
            _this4.dialogShow = false;
          }
          if (e.action == 'busyIb') {
            if (_this4.flags == 1) {
              var routeUrl = _this4.$router.resolve({
                path: '/myCustomerDetail?Cid=' + _this4.customer_id + '&CData_id=' + _this4.customer_data_id + '&action=1&editRole=1'
              });
              window.open(routeUrl.href, '_blank');
            }
          }
        });
      }
    },
    ByMobile: function ByMobile(customerNumber) {
      var _this5 = this;
      checkCustomerByMobile({
        mobile: customerNumber
      }).then(function (res) {
        var data = res.data;
        _this5.calls = '';
        _this5.MobileAddress = '';
        _this5.customer_id = '';
        _this5.customer_data_id = '';
        if (data.flag == 1) {
          _this5.calls = data.cname;
          _this5.MobileAddress = data.mobile_address;
          _this5.customer_id = data.customer_id;
          _this5.customer_data_id = data.customer_data_id;
        } else {
          _this5.calls = customerNumber;
          _this5.MobileAddress = data.mobile_address;
        }
        _this5.flags = data.flag;
        _this5.dialogShow = true;
      });
    },
    DialogClick: function DialogClick() {
      this.dialogShow = false;
    }
  },
  destroyed: function destroyed() {
    this.timerString = '00:00:00';
  }
};