// 主题文件数组
var themeArray = [
// 'Pagination',
// 'Dialog',
// 'Autocomplete',
// 'Dropdown',
// 'dropdown-menu',
// 'dropdown-item',
// 'Menu',
// 'Submenu',
// 'menu-item',
// 'menu-item-group',
// 'Input',
// 'input-number',
// 'Radio',
// 'radio-group',
// 'radio-button',
// 'Checkbox',
// 'checkbox-group',
'Switch',
// 'Select',
// 'Option',
// 'option-group',
'Button',
// 'button-group',
'Table', 'table-column',
// 'date-picker',
// 'time-select',
// 'time-picker',
// 'Popover',
// 'Tooltip',
'Breadcrumb', 'breadcrumb-item', 'Form', 'form-item', 'Tabs', 'tab-pane', 'Tag', 'Tree', 'Alert', 'Slider', 'Icon', 'Row', 'Col', 'Upload', 'Progress', 'Spinner', 'Badge', 'Card', 'Rate', 'Steps', 'Step', 'Carousel', 'Scrollbar', 'carousel-item', 'Collapse', 'collapse-item', 'Cascader', 'color-picker', 'Loading', 'message-box', 'Message'];
export default themeArray;