import "core-js/modules/es6.regexp.replace";
import Vue from 'vue';
import Router from 'vue-router';
var routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(function (error) {
    return error;
  });
};
var routerReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
  return routerReplace.call(this, location).catch(function (error) {
    return error;
  });
};
Vue.use(Router);

/* Layout */
import Layout from "../views/layout/Layout";
export var constantRouterMap = [{
  path: '/',
  redirect: '/homePage/hoemLock'
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login');
  },
  name: 'CRM',
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/errorPage/404');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/errorPage/401');
  },
  hidden: true
}, {
  path: '/profile',
  component: function component() {
    return import('@/views/profile');
  },
  hidden: true,
  meta: {
    title: ''
  }
}, {
  path: '/course',
  component: function component() {
    return import('@/views/course');
  },
  hidden: true
}, {
  path: '/addCustomer',
  component: function component() {
    return import('@/views/BasicOperation/customerManagement/accountCustomers/addCustomer');
  },
  hidden: true
}, {
  path: '/myCustomerDetail',
  component: function component() {
    return import('@/views/BasicOperation/customerManagement/accountCustomers/myCustomerDetail');
  },
  hidden: true
}, {
  path: '/receiveDetail',
  component: function component() {
    return import('@/views/BasicOperation/enterprise/myproject/receiveDetail');
  },
  hidden: true
}, {
  path: '/FollowDetail',
  component: function component() {
    return import('@/views/BasicOperation/customerManagement/accountCustomers/FollowDetail');
  },
  hidden: true
}, {
  path: '/sharedList',
  component: function component() {
    return import('@/views/BasicOperation/customerManagement/accountCustomers/sharedList');
  },
  hidden: true
}, {
  path: '/myteacherDetail',
  component: function component() {
    return import('@/views/BasicOperation/Educationa/myteacherDetail.vue');
  },
  hidden: true
}, {
  path: '/Allitemsdetail',
  component: function component() {
    return import('@/views/BasicOperation/enterprise/myproject/Allitemsdetail.vue');
  },
  hidden: true
}, {
  path: '/DeliverycustomerDetail',
  component: function component() {
    return import('@/views/BasicOperation/enterprise/myproject/DeliverycustomerDetail.vue');
  },
  hidden: true
}, {
  path: '/orderDetail',
  component: function component() {
    return import('@/views/BasicOperation/orderManagement/orderDetail.vue');
  },
  hidden: true
},
// {
//     path: '/userOrderDetails',
//     component: () => import('@/views/BasicOperation/customerManagement/accountCustomers/userOrderDetails'),
//     hidden: true
// },
{
  path: '/orderDetails',
  component: function component() {
    return import('@/views/BasicOperation/customerManagement/accountCustomers/orderDetails');
  },
  hidden: true
}, {
  path: '/orderDetailsts',
  component: function component() {
    return import('@/views/BasicOperation/customerManagement/accountCustomers/orderDetailsts');
  },
  hidden: true
}, {
  path: '/orderInfo',
  component: function component() {
    return import('@/views/BasicOperation/customerManagement/accountCustomers/orderInfo');
  },
  hidden: true
}];
var router = new Router({
  // mode: 'history', //后端支持可开
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRouterMap
});
export var asyncRouterMap = [{
  path: '/homePage',
  component: Layout,
  name: '看板',
  top: 'margin-top: -6px;',
  icon: 'iconfont icon-toolbar-kanban',
  meta: {
    title: '看板',
    icon: 'table',
    roles: ['admin']
  },
  children: [{
    path: 'hoemLock',
    name: 'hoemLock',
    component: function component() {
      return import('@/views/homePage/homeLock.vue');
    },
    meta: {
      title: '看板',
      icon: 'iconfont icon-toolbar-kanban'
    }
  }, {
    path: 'personalAnalysis',
    name: 'personalAnalysis',
    component: function component() {
      return import('@/views/homePage/personalAnalysis.vue');
    },
    meta: {
      title: '个人统计',
      icon: 'iconfont icon-gerentongji'
    }
  }, {
    path: 'SalesKanban',
    name: '销售看板',
    component: function component() {
      return import('@/views/homePage/SalesKanban.vue');
    },
    meta: {
      title: '销售看板',
      icon: 'iconfont icon-shujukanban',
      roles: ['admin']
    }
  }, {
    path: 'Historyup',
    name: '历史登陆',
    component: function component() {
      return import('@/views/profile/Historyup.vue');
    },
    meta: {
      title: '历史登陆',
      icon: 'iconfont icon-lishi',
      roles: ['admin']
    }
  }, {
    path: 'MessageManagement',
    name: '消息管理',
    component: function component() {
      return import('@/views/SystemManagement/MessageManagement');
    },
    meta: {
      title: '消息管理',
      icon: 'iconfont icon-xiaoxi'
    }
  }]
}, {
  path: '/BasicOperation',
  component: Layout,
  name: '基础操作',
  top: 'margin-top: -6px;',
  icon: 'iconfont icon-shoushi',
  meta: {
    title: '基础操作',
    icon: 'table',
    roles: ['admin']
  },
  children: [{
    path: 'customerManagement',
    name: '客户管理',
    component: {
      render: function render(c) {
        return c('router-view');
      }
    },
    meta: {
      title: '客户管理',
      icon: 'iconfont icon-kehu',
      roles: ['admin']
    },
    children: [{
      path: 'accountCustomers',
      name: '账户客户',
      component: {
        render: function render(c) {
          return c('router-view');
        }
      },
      meta: {
        title: '账户客户',
        icon: 'emoji',
        roles: ['admin']
      },
      children: [{
        path: 'whole',
        name: '全部客户',
        component: function component() {
          return import('@/views/BasicOperation/customerManagement/accountCustomers/whole');
        },
        meta: {
          title: '全部客户',
          icon: 'emoji',
          roles: ['admin']
        }
      }, {
        path: 'Created',
        name: '创建的客户',
        component: function component() {
          return import('@/views/BasicOperation/customerManagement/accountCustomers/Created');
        },
        meta: {
          title: '创建的客户',
          icon: 'emoji',
          roles: ['admin']
        }
      }, {
        path: 'Shared',
        name: '共享的客户',
        component: function component() {
          return import('@/views/BasicOperation/customerManagement/accountCustomers/Shared');
        },
        meta: {
          title: '共享的客户',
          icon: 'emoji',
          roles: ['admin']
        }
      }, {
        path: 'noReturnvisitwhole',
        name: '未回访客户',
        component: function component() {
          return import('@/views/BasicOperation/customerManagement/accountCustomers/noReturnvisit');
        },
        meta: {
          title: '未回访客户',
          icon: 'emoji',
          roles: ['admin']
        }
      }, {
        path: 'Circulation',
        name: '已流转客户',
        component: function component() {
          return import('@/views/BasicOperation/customerManagement/accountCustomers/Circulation');
        },
        meta: {
          title: '已流转客户',
          icon: 'emoji',
          roles: ['admin']
        }
      }]
    }, {
      path: 'HighSeasCustomers',
      name: '公海池',
      component: {
        render: function render(c) {
          return c('router-view');
        }
      },
      meta: {
        title: '公海池',
        icon: 'emoji',
        roles: ['admin']
      },
      children: [{
        path: 'Sendcustomerstohighseas',
        name: '外送公海客户池',
        component: function component() {
          return import('@/views/BasicOperation/customerManagement/HighSeasCustomers/Sendcustomerstohighseas');
        },
        meta: {
          title: '外送公海客户池',
          icon: 'emoji',
          roles: ['admin']
        }
      }, {
        path: 'Department',
        name: '部门公海客户池',
        component: function component() {
          return import('@/views/BasicOperation/customerManagement/HighSeasCustomers/Department');
        },
        meta: {
          title: '部门公海客户池',
          icon: 'emoji',
          roles: ['admin']
        }
      }, {
        path: 'Sendcustomerstwo',
        name: '部门二级公海池',
        component: function component() {
          return import('@/views/BasicOperation/customerManagement/HighSeasCustomers/Sendcustomerstwo');
        },
        meta: {
          title: '部门二级公海池',
          icon: 'emoji',
          roles: ['admin']
        }
      }]
    }, {
      path: 'reported',
      name: '已共享客户',
      component: function component() {
        return import('@/views/BasicOperation/customerManagement/accountCustomers/reported');
      },
      meta: {
        title: '已共享客户',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'PromoteRetained',
      name: '待分配数据',
      component: function component() {
        return import('@/views/BasicOperation/customerManagement/accountCustomers/PromoteRetained');
      },
      meta: {
        title: '待分配数据',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'Follow',
      name: '跟进记录',
      component: function component() {
        return import('@/views/BasicOperation/customerManagement/accountCustomers/Follow');
      },
      meta: {
        title: '跟进记录',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'personalStatistics',
      name: '个人统计',
      component: function component() {
        return import('@/views/BasicOperation/customerManagement/personalStatistics/index');
      },
      meta: {
        title: '个人统计',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'freeRegistration',
      name: '商城免费报名客户',
      component: function component() {
        return import('@/views/BasicOperation/customerManagement/freeRegistration/index');
      },
      meta: {
        title: '商城免费报名客户',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'Customerrecords',
      name: '客户新增记录',
      component: function component() {
        return import('@/views/BasicOperation/customerManagement/Customer/Customerrecords.vue');
      },
      meta: {
        title: '客户新增记录',
        icon: 'emoji',
        roles: ['admin']
      }
    }]
  }, {
    path: 'orderManagement',
    name: '订单管理',
    component: {
      render: function render(c) {
        return c('router-view');
      }
    },
    meta: {
      title: '订单管理',
      icon: 'iconfont icon-dingdan',
      roles: ['admin']
    },
    children: [{
      path: 'orderSubmitted',
      name: '审核中订单',
      component: function component() {
        return import('@/views/BasicOperation/orderManagement/orderSubmitted');
      },
      meta: {
        title: '审核中订单',
        icon: 'fa fa-file-text',
        roles: ['admin']
      }
    }, {
      path: 'orderRejected',
      name: '未通过订单',
      component: function component() {
        return import('@/views/BasicOperation/orderManagement/orderRejected');
      },
      meta: {
        title: '未通过订单',
        icon: 'fa fa-file-text',
        roles: ['admin']
      }
    }, {
      path: 'orderCharge',
      name: '主管审核订单',
      component: function component() {
        return import('@/views/BasicOperation/orderManagement/orderCharge');
      },
      meta: {
        title: '主管审核订单',
        icon: 'fa fa-file-text',
        roles: ['admin']
      }
    }, {
      path: 'orderPass',
      name: '已通过订单',
      component: function component() {
        return import('@/views/BasicOperation/orderManagement/orderPass');
      },
      meta: {
        title: '已通过订单',
        icon: 'fa fa-file-text',
        roles: ['admin']
      }
    }, {
      path: 'orderCancel',
      name: '已取消订单',
      component: function component() {
        return import('@/views/BasicOperation/orderManagement/orderCancel');
      },
      meta: {
        title: '已取消订单',
        icon: 'fa fa-file-text',
        roles: ['admin']
      }
    }]
  }, {
    path: 'contactRecord',
    name: '联系记录',
    component: {
      render: function render(c) {
        return c('router-view');
      }
    },
    meta: {
      title: '联系记录',
      icon: 'iconfont icon-lianxijilu',
      roles: ['admin']
    },
    children: [{
      path: 'callCenter',
      name: '通话记录',
      component: function component() {
        return import('@/views/BasicOperation/customerManagement/callCenter/index');
      },
      meta: {
        title: '通话记录',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'callingCenter',
      name: '呼叫中心',
      component: function component() {
        return import('@/views/BasicOperation/contactRecord/callingCenter');
      },
      meta: {
        title: '呼叫中心',
        icon: 'emoji',
        roles: ['admin']
      }
    }]
  }, {
    path: 'Educationa',
    name: '教务管理',
    component: {
      render: function render(c) {
        return c('router-view');
      }
    },
    meta: {
      title: '教务管理',
      icon: 'iconfont icon-wenzhang-copy',
      roles: ['admin']
    },
    children: [{
      path: 'Myteacher',
      name: '我的师资',
      component: function component() {
        return import('@/views/BasicOperation/Educationa/Myteacher');
      },
      meta: {
        title: '我的师资',
        icon: 'fa fa-file-text',
        roles: ['admin']
      }
    }]
  }, {
    path: 'product',
    name: '产品管理',
    component: {
      render: function render(c) {
        return c('router-view');
      }
    },
    meta: {
      title: '产品管理',
      icon: 'iconfont icon-3D',
      roles: ['admin']
    },
    children: [{
      path: 'CollegeManagement',
      name: '院校管理',
      component: function component() {
        return import('@/views/BasicOperation/product/CollegeManagement');
      },
      meta: {
        title: '院校管理',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'Major',
      name: '专业管理',
      component: function component() {
        return import('@/views/BasicOperation/product/Major');
      },
      meta: {
        title: '专业管理',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'ClassManagement',
      name: '班级管理',
      component: function component() {
        return import('@/views/BasicOperation/product/ClassManagement');
      },
      meta: {
        title: '班级管理',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'direction',
      name: '方向管理',
      component: function component() {
        return import('@/views/BasicOperation/product/Direction');
      },
      meta: {
        title: '方向管理',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'Generalrules',
      name: '简章管理',
      component: function component() {
        return import('@/views/BasicOperation/product/Generalrules');
      },
      meta: {
        title: '简章管理',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'productDataList',
      name: '产品数据列表',
      component: function component() {
        return import('@/views/BasicOperation/product/productDataList');
      },
      meta: {
        title: '产品数据列表',
        icon: 'emoji',
        roles: ['admin']
      }
    }]
  }, {
    //zml企业管理
    path: 'enterprise',
    component: {
      render: function render(c) {
        return c('router-view');
      }
    },
    meta: {
      title: '企业管理',
      icon: 'iconfont icon-fill_squarestack3dup',
      roles: ['admin']
    },
    children: [{
      path: 'Allitems',
      name: '全部企业',
      meta: {
        title: '全部企业',
        icon: 'emoji',
        roles: ['admin']
      },
      component: function component() {
        return import('@/views/BasicOperation/enterprise/myproject/Allitems.vue');
      }
    }, {
      path: 'deliveryCustomerInfo',
      name: '外送客户信息',
      meta: {
        title: '外送客户信息',
        icon: 'emoji',
        roles: ['admin']
      },
      component: function component() {
        return import('@/views/BasicOperation/enterprise/myproject/Deliverycustomerinfo.vue');
      }
    }, {
      path: 'receive',
      name: '接收客户信息',
      meta: {
        title: '接收客户信息',
        icon: 'emoji',
        roles: ['admin']
      },
      component: function component() {
        return import('@/views/BasicOperation/enterprise/myproject/receive.vue');
      }
    }]
  }, {
    //jianzhong 话术管理
    path: 'Languagemanagement',
    component: {
      render: function render(c) {
        return c('router-view');
      }
    },
    meta: {
      title: '话术库管理',
      icon: 'iconfont icon-huashu1',
      roles: ['admin']
    },
    children: [{
      path: 'language',
      name: '全部话术参考',
      meta: {
        title: '全部话术参考',
        roles: ['admin']
      },
      component: function component() {
        return import('@/views/BasicOperation/Languagemanagement/language.vue');
      }
    }]
  }, {
    path: 'finance',
    name: '财务管理',
    component: {
      render: function render(c) {
        return c('router-view');
      }
    },
    meta: {
      title: '财务管理',
      icon: 'iconfont icon-qianbao',
      roles: ['admin']
    },
    children: [{
      path: 'ToBeReviewed',
      name: '待审核订单',
      meta: {
        title: '待审核订单',
        roles: ['admin']
      },
      component: function component() {
        return import('@/views/BasicOperation/finance/ToBeReviewed.vue');
      }
    }, {
      path: 'OrderPassed',
      name: '已通过订单',
      meta: {
        title: '已通过订单',
        roles: ['admin']
      },
      component: function component() {
        return import('@/views/BasicOperation/finance/OrderPassed.vue');
      }
    }, {
      path: 'RejectOrder',
      name: '已驳回订单',
      meta: {
        title: '已驳回订单',
        roles: ['admin']
      },
      component: function component() {
        return import('@/views/BasicOperation/finance/RejectOrder.vue');
      }
    }, {
      path: 'Examine',
      name: '已审核订单',
      meta: {
        title: '已审核订单',
        roles: ['admin']
      },
      component: function component() {
        return import('@/views/BasicOperation/finance/ExamineOrder.vue');
      }
    }]
  }, {
    //yay 推广管理
    path: 'extensionManagement',
    name: '推广管理',
    component: {
      render: function render(c) {
        return c('router-view');
      }
    },
    meta: {
      title: '推广管理',
      icon: 'iconfont icon-yunyingtuiguang',
      roles: ['admin']
    },
    children: [{
      path: 'extensionPlatform',
      name: '推广平台管理',
      meta: {
        title: '推广平台管理',
        roles: ['admin']
      },
      component: function component() {
        return import('@/views/BasicOperation/extensionManagement/extensionPlatform.vue');
      }
    }, {
      path: 'AccountManagement',
      name: '推广账户管理',
      meta: {
        title: '推广账户管理',
        roles: ['admin']
      },
      component: function component() {
        return import('@/views/BasicOperation/extensionManagement/AccountManagement.vue');
      }
    }, {
      path: 'extensionProject',
      name: '推广项目管理',
      meta: {
        title: '推广项目管理',
        roles: ['admin']
      },
      component: function component() {
        return import('@/views/BasicOperation/extensionManagement/extensionProject.vue');
      }
    }, {
      path: 'landingPage',
      name: '落地页管理',
      meta: {
        title: '落地页管理',
        roles: ['admin']
      },
      component: function component() {
        return import('@/views/BasicOperation/extensionManagement/landingPage.vue');
      }
    }, {
      path: 'dataDistribution',
      name: '推广数据分配',
      meta: {
        title: '推广数据分配',
        roles: ['admin']
      },
      component: function component() {
        return import('@/views/BasicOperation/extensionManagement/dataDistribution.vue');
      }
    }]
  }, {
    //yay 数据分配
    path: 'dataDistribution',
    name: '数据分配',
    component: {
      render: function render(c) {
        return c('router-view');
      }
    },
    meta: {
      title: '数据分配',
      icon: 'iconfont icon-shujufenpei',
      roles: ['admin']
    },
    children: [{
      path: 'offlineDistribution',
      name: '线下数据分配',
      meta: {
        title: '线下数据分配',
        roles: ['admin']
      },
      component: function component() {
        return import('@/views/BasicOperation/dataDistribution/offlineDistribution.vue');
      }
    }]
  }, {
    path: 'distributionManagement',
    name: '销售分配管理',
    component: {
      render: function render(c) {
        return c('router-view');
      }
    },
    meta: {
      title: '销售分配管理',
      icon: 'iconfont icon-fenpeiguanli--',
      roles: ['admin']
    },
    children: [{
      path: 'weightDistribution',
      name: '权重配置',
      component: function component() {
        return import('@/views/SystemManagement/distributionManagement/weightDistribution');
      },
      meta: {
        title: '权重配置',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'Staffing',
      name: '人员级别配置',
      component: function component() {
        return import('@/views/SystemManagement/distributionManagement/Staffing');
      },
      meta: {
        title: '人员级别配置',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'DataReceiving',
      name: '数据接收配置',
      component: function component() {
        return import('@/views/SystemManagement/distributionManagement/DataReceiving');
      },
      meta: {
        title: '数据接收配置',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'departmentAllocation',
      name: '部门流量分配',
      component: function component() {
        return import('@/views/SystemManagement/distributionManagement/departmentAllocation');
      },
      meta: {
        title: '部门流量分配',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'receiveConfig',
      name: '数据接收详情',
      component: function component() {
        return import('@/views/SystemManagement/distributionManagement/receiveConfig');
      },
      meta: {
        title: '数据接收详情',
        icon: 'emoji',
        roles: ['admin']
      }
    }]
  },
  // 薪酬绩效管理
  {
    path: 'salaryEffectiveManagement',
    name: '薪酬绩效管理',
    component: {
      render: function render(c) {
        return c('router-view');
      }
    },
    meta: {
      title: '薪酬绩效管理',
      icon: 'iconfont icon-xinchouguanli',
      roles: ['admin']
    },
    children: [{
      path: 'keyPerformanceConfig',
      name: '业绩指标配置',
      component: function component() {
        return import('@/views/SystemManagement/salaryEffectiveManagement/keyPerformanceConfig');
      },
      meta: {
        title: '业绩指标配置',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'saleCommisionData',
      name: '销售提成数据',
      component: function component() {
        return import('@/views/SystemManagement/salaryEffectiveManagement/saleCommisionData');
      },
      meta: {
        title: '销售提成数据',
        icon: 'emoji',
        roles: ['admin']
      }
    }]
  }]
}, {
  path: '/SystemManagement',
  component: Layout,
  name: '系统管理',
  top: 'margin-top: -6px;',
  icon: 'iconfont icon-shezhi',
  meta: {
    title: '系统管理',
    icon: 'right-mean',
    roles: ['admin']
  },
  children: [{
    path: 'Permission',
    name: '权限设定',
    component: {
      render: function render(c) {
        return c('router-view');
      }
    },
    meta: {
      title: '权限设定',
      icon: 'iconfont icon-quanxian',
      roles: ['admin']
    },
    children: [{
      path: 'company',
      name: '公司组织架构',
      component: function component() {
        return import('@/views/SystemManagement/Permission/company');
      },
      meta: {
        title: '公司组织架构',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'TrOutbound',
      name: '外呼管理',
      component: function component() {
        return import('@/views/SystemManagement/Permission/TrOutbound');
      },
      meta: {
        title: '外呼管理',
        icon: 'emoji',
        roles: ['admin']
      }
    },
    // {
    //     path: 'outbound',
    //     name: '外呼管理',
    //     component: () => import('@/views/SystemManagement/Permission/outbound'),
    //     meta: {
    //         title: '外呼管理',
    //         icon: 'emoji',
    //         roles: ['admin']
    //     }
    // },
    {
      path: 'postAuthority',
      name: '岗位权限',
      component: function component() {
        return import('@/views/SystemManagement/Permission/postAuthority');
      },
      meta: {
        title: '岗位权限',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'PersonnelManagement',
      name: '人员管理',
      component: function component() {
        return import('@/views/SystemManagement/Permission/PersonnelManagement');
      },
      meta: {
        title: '人员管理',
        icon: 'emoji',
        roles: ['admin']
      }
      // hah
    }]
  }, {
    path: 'menu',
    name: '菜单管理',
    component: function component() {
      return import('@/views/SystemManagement/menu');
    },
    meta: {
      title: '菜单管理',
      icon: 'iconfont icon-caidan',
      roles: ['admin']
    }
  }, {
    path: 'MessageManagement',
    name: '消息管理',
    component: function component() {
      return import('@/views/SystemManagement/MessageManagement');
    },
    meta: {
      title: '消息管理',
      icon: 'iconfont icon-xiaoxi',
      roles: ['admin']
    }
  }, {
    path: 'journal',
    name: '操作日志',
    component: {
      render: function render(c) {
        return c('router-view');
      }
    },
    meta: {
      title: '操作日志',
      icon: 'iconfont icon-rizhi',
      roles: ['admin']
    },
    children: [{
      path: 'OperationLog',
      name: '日志查询',
      component: function component() {
        return import('@/views/SystemManagement/Journal/OperationLog');
      },
      meta: {
        title: '日志查询',
        icon: 'emoji',
        roles: ['admin']
      }
    }]
  }, {
    path: 'notice',
    name: '系统通告',
    component: {
      render: function render(c) {
        return c('router-view');
      }
    },
    meta: {
      title: '系统通告',
      icon: 'iconfont icon-tonggao',
      roles: ['admin']
    },
    children: [{
      path: 'index',
      name: '系统公告',
      component: function component() {
        return import('@/views/SystemManagement/notice/index');
      },
      meta: {
        title: '系统公告',
        icon: 'emoji',
        roles: ['admin']
      }
    }]
  }, {
    path: 'SystemConfiguration',
    name: '系统配置',
    component: {
      render: function render(c) {
        return c('router-view');
      }
    },
    meta: {
      title: '系统配置',
      icon: 'iconfont icon-xitong',
      roles: ['admin']
    },
    children: [{
      path: 'configuration',
      name: '学员级别配置',
      component: function component() {
        return import('@/views/SystemManagement/SystemConfiguration/configuration');
      },
      meta: {
        title: '学员级别配置',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'PrivateSeaData',
      name: '私海数据配置',
      component: function component() {
        return import('@/views/SystemManagement/SystemConfiguration/PrivateSeaData');
      },
      meta: {
        title: '私海数据配置',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'exCityConfig',
      name: '展业城市配置',
      component: function component() {
        return import('@/views/SystemManagement/SystemConfiguration/exCityConfig');
      },
      meta: {
        title: '展业城市配置',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'PersonnelMobile',
      name: '人员手机配置',
      component: function component() {
        return import('@/views/SystemManagement/SystemConfiguration/PersonnelMobile');
      },
      meta: {
        title: '人员手机配置',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'ApprovalProcess',
      name: '审批流程配置',
      component: function component() {
        return import('@/views/SystemManagement/SystemConfiguration/ApprovalProcess');
      },
      meta: {
        title: '审批流程配置',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'ScorePage',
      name: '参与得分配置',
      component: function component() {
        return import('@/views/SystemManagement/SystemConfiguration/ScorePage');
      },
      meta: {
        title: '参与得分配置',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'GradingPersonnel',
      name: '定级人员配置',
      component: function component() {
        return import('@/views/SystemManagement/SystemConfiguration/GradingPersonnel');
      },
      meta: {
        title: '定级人员配置',
        icon: 'emoji',
        roles: ['admin']
      }
    }]
  }, {
    path: 'RuleSettings',
    name: '规则设置',
    component: {
      render: function render(c) {
        return c('router-view');
      }
    },
    meta: {
      title: '规则设置',
      icon: 'iconfont icon-zu524',
      roles: ['admin']
    },
    children: [{
      path: 'DataRecycling',
      name: '数据回收规则',
      component: function component() {
        return import('@/views/SystemManagement/RuleSettings/DataRecycling');
      },
      meta: {
        title: '数据回收规则',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'gradingRules',
      name: '得分规则配置',
      component: function component() {
        return import('@/views/SystemManagement/RuleSettings/gradingRules');
      },
      meta: {
        title: '得分规则配置',
        icon: 'emoji',
        roles: ['admin']
      }
    }]
  }, {
    path: 'CRMOperationManagement',
    name: 'CRM运维管理',
    component: {
      render: function render(c) {
        return c('router-view');
      }
    },
    meta: {
      title: 'CRM运维管理',
      icon: 'iconfont icon-yunweiguanli',
      roles: ['admin']
    },
    children: [{
      path: 'databaseSearch',
      name: '数据库SQL查询',
      component: function component() {
        return import('@/views/SystemManagement/CRMOperationManagement/databaseSearch');
      },
      meta: {
        title: '数据库SQL查询',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'businessLog',
      name: '业务日志查询',
      component: function component() {
        return import('@/views/SystemManagement/CRMOperationManagement/businessLog');
      },
      meta: {
        title: '业务日志查询',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'studentSource',
      name: '学员来源修改',
      component: function component() {
        return import('@/views/SystemManagement/CRMOperationManagement/studentSource');
      },
      meta: {
        title: '学员来源修改',
        icon: 'emoji',
        roles: ['admin']
      }
    }, {
      path: 'apiOperation',
      name: 'API操作日志',
      component: function component() {
        return import('@/views/SystemManagement/CRMOperationManagement/apiOperation');
      },
      meta: {
        title: 'API操作日志',
        icon: 'emoji',
        roles: ['admin']
      }
    }]
  }]
}, {
  path: '/ManagementAnalysis',
  name: '管理分析',
  icon: 'iconfont icon-tongji',
  top: 'margin-top: -6px;',
  meta: {
    title: '管理分析',
    icon: 'table',
    roles: ['admin']
  },
  component: Layout,
  children: [{
    //zml管理下级
    path: 'Managesubordinates',
    component: {
      render: function render(c) {
        return c('router-view');
      }
    },
    meta: {
      title: '管理下级',
      icon: 'iconfont icon-qiye',
      roles: ['admin']
    },
    children: [{
      path: 'department',
      component: {
        render: function render(c) {
          return c('router-view');
        }
      },
      meta: {
        title: '部门数据',
        icon: 'emoji',
        roles: ['admin']
      },
      children: [{
        path: 'Statistics',
        name: '数据统计',
        component: function component() {
          return import('@/views/ManagementAnalysis/Managesubordinates/department/Statistics');
        },
        meta: {
          title: '数据统计',
          icon: 'emoji',
          roles: ['admin']
        }
      }, {
        path: 'Studentstatistics',
        name: '学员级别统计',
        component: function component() {
          return import('@/views/ManagementAnalysis/Managesubordinates/department/Studentstatistics');
        },
        meta: {
          title: '学员级别统计',
          icon: 'emoji',
          roles: ['admin']
        }
      }, {
        path: 'Circulation',
        name: '流转数据统计',
        component: function component() {
          return import('@/views/ManagementAnalysis/Managesubordinates/department/Circulation');
        },
        meta: {
          title: '流转数据统计',
          icon: 'emoji',
          roles: ['admin']
        }
      }, {
        path: 'Conversation',
        name: '通话时长统计',
        component: function component() {
          return import('@/views/ManagementAnalysis/Managesubordinates/department/Conversation');
        },
        meta: {
          title: '通话时长统计',
          icon: 'emoji',
          roles: ['admin']
        }
      }, {
        path: 'TianrunCall',
        name: '通话数据统计',
        component: function component() {
          return import('@/views/ManagementAnalysis/Managesubordinates/department/TianrunCall');
        },
        meta: {
          title: '通话数据统计',
          icon: 'emoji',
          roles: ['admin']
        }
      }, {
        path: 'incomingCall',
        name: '呼入通话统计',
        component: function component() {
          return import('@/views/ManagementAnalysis/Managesubordinates/department/incomingCall');
        },
        meta: {
          title: '呼入通话统计',
          icon: 'emoji',
          roles: ['admin']
        }
      }, {
        path: 'TrafficSharing',
        name: '流量共享统计',
        component: function component() {
          return import('@/views/ManagementAnalysis/Managesubordinates/department/TrafficSharing');
        },
        meta: {
          title: '流量共享统计',
          icon: 'emoji',
          roles: ['admin']
        }
      }, {
        path: 'ChannelStatistics',
        name: '渠道来源统计',
        component: function component() {
          return import('@/views/ManagementAnalysis/Managesubordinates/department/ChannelStatistics');
        },
        meta: {
          title: '渠道来源统计',
          icon: 'emoji',
          roles: ['admin']
        }
      }]
    }, {
      path: 'operation',
      component: {
        render: function render(c) {
          return c('router-view');
        }
      },
      meta: {
        title: '操作下级',
        icon: 'emoji',
        roles: ['admin']
      },
      children: [{
        path: 'datamanagement',
        name: '全部数据',
        component: function component() {
          return import('@/views/ManagementAnalysis/Managesubordinates/operation/datamanagement');
        },
        meta: {
          title: '全部数据',
          icon: 'emoji',
          roles: ['admin']
        }
      }]
    }]
  }, {
    //数据分析
    path: 'Dataanalysis',
    component: {
      render: function render(c) {
        return c('router-view');
      }
    },
    meta: {
      title: '数据分析',
      icon: 'iconfont icon-shuju',
      roles: ['admin']
    },
    children: [{
      path: 'trend',
      component: {
        render: function render(c) {
          return c('router-view');
        }
      },
      meta: {
        title: '趋势分析',
        icon: 'emoji',
        roles: ['admin']
      },
      children: [{
        path: 'trendanalysis',
        name: '趋势分析',
        component: function component() {
          return import('@/views/ManagementAnalysis/Dataanalysis/trend/trendanalysis');
        },
        meta: {
          title: '趋势分析',
          icon: 'emoji',
          roles: ['admin']
        }
      }]
    }, {
      path: 'CustomerAnalysis',
      component: {
        render: function render(c) {
          return c('router-view');
        }
      },
      meta: {
        title: '客户分析',
        icon: 'emoji',
        roles: ['admin']
      },
      children: [{
        path: 'allData',
        name: '全部客户',
        component: function component() {
          return import('@/views/ManagementAnalysis/Dataanalysis/CustomerAnalysis/allData');
        },
        meta: {
          title: '全部客户',
          icon: 'emoji',
          roles: ['admin']
        }
      }, {
        path: 'sharedCustomers',
        name: '共享客户数据',
        component: function component() {
          return import('@/views/ManagementAnalysis/Dataanalysis/CustomerAnalysis/sharedCustomers');
        },
        meta: {
          title: '共享客户数据',
          icon: 'emoji',
          roles: ['admin']
        }
      }, {
        path: 'GradingData',
        name: '人员得分数据',
        component: function component() {
          return import('@/views/ManagementAnalysis/Dataanalysis/CustomerAnalysis/GradingData');
        },
        meta: {
          title: '人员得分数据',
          icon: 'emoji',
          roles: ['admin']
        }
      }, {
        path: 'LevelData',
        name: '人员定级数据',
        component: function component() {
          return import('@/views/ManagementAnalysis/Dataanalysis/CustomerAnalysis/LevelData');
        },
        meta: {
          title: '人员定级数据',
          icon: 'emoji',
          roles: ['admin']
        }
      }, {
        path: 'PlaceOfAttribution',
        name: '归属地',
        component: function component() {
          return import('@/views/ManagementAnalysis/Dataanalysis/CustomerAnalysis/PlaceOfAttribution');
        },
        meta: {
          title: '归属地',
          icon: 'emoji',
          roles: ['admin']
        }
      }, {
        path: 'ApplicationItems',
        name: '报考项目',
        component: function component() {
          return import('@/views/ManagementAnalysis/Dataanalysis/CustomerAnalysis/ApplicationItems');
        },
        meta: {
          title: '报考项目',
          icon: 'emoji',
          roles: ['admin']
        }
      }, {
        path: 'institutions',
        name: '意向院校',
        component: function component() {
          return import('@/views/ManagementAnalysis/Dataanalysis/CustomerAnalysis/institutions');
        },
        meta: {
          title: '意向院校',
          icon: 'emoji',
          roles: ['admin']
        }
      }, {
        path: 'major',
        name: '意向专业',
        component: function component() {
          return import('@/views/ManagementAnalysis/Dataanalysis/CustomerAnalysis/major');
        },
        meta: {
          title: '意向专业',
          icon: 'emoji',
          roles: ['admin']
        }
      }]
    }, {
      path: 'Analysis',
      name: '推广分析',
      component: function component() {
        return import('@/views/ManagementAnalysis/Dataanalysis/Extension/Analysis');
      },
      meta: {
        title: '推广分析',
        icon: 'emoji',
        roles: ['admin']
      }
      // children: [
      //     {
      //         path: 'Sourceanalysis',
      //         name: '来源分析',
      //         component: () => import('@/views/ManagementAnalysis/Dataanalysis/source/Sourceanalysis'),
      //         meta: {
      //             title: '来源分析',
      //             icon: 'emoji',
      //             roles: ['admin']
      //         }
      //     }
      // ]
    }, {
      path: 'source',
      component: {
        render: function render(c) {
          return c('router-view');
        }
      },
      meta: {
        title: '来源分析',
        icon: 'emoji',
        roles: ['admin']
      },
      children: [{
        path: 'Sourceanalysis',
        name: '来源分析',
        component: function component() {
          return import('@/views/ManagementAnalysis/Dataanalysis/source/Sourceanalysis');
        },
        meta: {
          title: '来源分析',
          icon: 'emoji',
          roles: ['admin']
        }
      }]
    }, {
      path: 'Followup',
      component: {
        render: function render(c) {
          return c('router-view');
        }
      },
      meta: {
        title: '跟进分析',
        icon: 'emoji',
        roles: ['admin']
      },
      children: [{
        path: 'Followupanalysis',
        name: '跟进分析',
        component: function component() {
          return import('@/views/ManagementAnalysis/Dataanalysis/Followup/Followupanalysis');
        },
        meta: {
          title: '跟进分析',
          icon: 'emoji',
          roles: ['admin']
        }
      }]
    }]
  }]
}
// { path: '*', redirect: '/404', hidden: true }
];

// router.selfaddRoutes = function (params){
//   router.matcher = new Router().matcher;
//   router.addRoutes(params)
// }
export default router;