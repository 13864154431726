var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-scrollbar",
    { attrs: { wrapClass: "scrollbar-wrapper" } },
    [
      _c("logo", { attrs: { isCollapse: _vm.isCollapse } }),
      _vm._v(" "),
      _c(
        "el-menu",
        {
          attrs: {
            mode: "vertical",
            "show-timeout": 200,
            "default-active": _vm.$route.path,
            collapse: _vm.isCollapse,
            "background-color": "#6959CD",
            "text-color": "white",
            "active-text-color": "#42b983",
          },
        },
        [
          _c("sidebar-item", {
            attrs: {
              sidebaritemDatas: _vm.sidebaritemData,
              activeNames: _vm.activeNames,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }