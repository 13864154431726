import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.replace";
//
//
//
//
//
//
//
//
//

import 'vue-transition.css';
import store from '@/store';
import { layoutRead, sendmsg } from '@/api/login';
import { addListener, launch } from 'devtools-detector';
import { getStatusData } from '@/api/ceshi_xiugai/order';
import { getTokens } from '@/utils/auth';
export default {
  name: 'App',
  computed: {
    userId: function userId() {
      return store.state.user.id;
    }
  },
  created: function created() {
    var _this = this;
    var deBug = this.getUrlKey('nameceshi');
    if (process.env.VUE_APP_BASE_API == 'production') {
      if (deBug != '测试') {
        addListener(function (isOpen) {
          return isOpen ? setInterval(function () {
            _this.check();
          }, 1000) : clearInterval();
        });
        launch();
        this.$nextTick(function () {
          // 禁止右键
          document.oncontextmenu = new Function('event.returnValue=false');
          // 禁止F12
          document.onkeydown = function (e) {
            if (e && e.keyCode === 123) {
              e.returnValue = false;

              // e.keyCode = 0   //去掉也可以的，倘若要写，则需要setter 以及 getter配合使用，不配合，会报错

              return false;
            }
          };
        });
      }
    }
  },
  methods: {
    getUrlKey: function getUrlKey(name) {
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null;
    },
    check: function check() {
      function doCheck(a) {
        if (('' + a / a)['length'] !== 1 || a % 20 === 0) {
          (function () {})['constructor']('debugger')();
        } else {
          (function () {})['constructor']('debugger')();
        }
        doCheck(++a);
      }
      try {
        doCheck(0);
      } catch (err) {}
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    var that = this;
    var ids = localStorage.getItem('id');
    if (ids != '' && ids != null) {
      var baseUrl = process.env.VUE_APP_BASE_API3;
      var socket = io(baseUrl);
      // socket.on('connection', function (socket) {
      socket.on('connect', function () {
        socket.emit('login', ids);
      });
      socket.on('new_msg', function (msg) {
        var fixMsg = JSON.parse(msg.replace(/&quot;/g, '"'));
        console.log('监听到新消息了', fixMsg);
        if (fixMsg.type === 'notice') {
          var arrEntities = {
            lt: '<',
            gt: '>',
            nbsp: ' ',
            amp: '&',
            quot: '"'
          };
          fixMsg.content = fixMsg.content.replace(/&(lt|gt|nbsp|amp|quot);/g, function (all, t) {
            return arrEntities[t];
          });
          var str = '';
          str = fixMsg.content.replace(/&lt;/g, '<');
          str = str.replace(/&gt;/g, '>');
          str = str.replace(/nbsp;/g, ' ');
          that.$alert("<h2 class='msg-title'>".concat(fixMsg.title, "</h2>") + fixMsg.content, '系统公告', {
            dangerouslyUseHTMLString: true,
            lockScroll: true,
            customClass: 'message'
          });
        }
        if (fixMsg.to_uid == store.getters.id) {
          sendmsg().then(function (res) {
            _this2.newsCliEs = res.data.unread_num;
            localStorage.setItem('shuliang', _this2.newsCliEs);
          });
          console.log(fixMsg.content);
          if (fixMsg.title == '撞单提醒') {
            that.$notify({
              title: fixMsg.title,
              dangerouslyUseHTMLString: true,
              message: "\n                                    <div class=\"lh24 g6 mt20\">\n                                    <div class=\"\">\u6D88\u606F\u5185\u5BB9\uFF1A".concat(fixMsg.content, " </div>\n                                    <div class=\"\">\u6DFB\u52A0\u65F6\u95F4\uFF1A").concat(fixMsg.create_time, "</div>\n                                    <div class=\"tc poi mt20  blueFont\" id=\"btn\">\u8BBE\u7F6E\u6210\u5DF2\u8BFB</div>\n                                    </div>"),
              position: 'bottom-right',
              duration: 20000
            });
          } else {
            if (fixMsg.type == 8 || fixMsg.type == 9) {
              that.$notify({
                title: fixMsg.title,
                dangerouslyUseHTMLString: true,
                message: "\n                                        <div class=\"lh24 g6 mt20\">\n                                        <div class=\"\">\u6D88\u606F\u5185\u5BB9\uFF1A".concat(fixMsg.content, " </div>\n                                        <div class=\"\">\u6DFB\u52A0\u65F6\u95F4\uFF1A").concat(fixMsg.create_time, "</div>\n                                        <div class=\"tc poi mt20  blueFont\" id=\"btn\">\u8BBE\u7F6E\u6210\u5DF2\u8BFB</div>\n                                        </div>"),
                position: 'bottom-right',
                duration: 0,
                onClick: function onClick() {
                  layoutRead({
                    ids: fixMsg.id
                  }).then(function (respomse) {
                    sendmsg().then(function (res) {
                      _this2.newsCliEs = res.data.unread_num;
                      localStorage.setItem('shuliang', _this2.newsCliEs);
                    });
                  });
                }
              });
            } // 预约短信提醒
            else if (fixMsg.type == '12') {
              var h = _this2.$createElement;
              that.instance = that.$notify({
                title: fixMsg.title,
                dangerouslyUseHTMLString: true,
                message: h('div', {
                  class: 'lh24 g6 mt20'
                }, [h('div', null, "".concat(fixMsg.content)), h('div', null, "\u6DFB\u52A0\u65F6\u95F4\uFF1A".concat(fixMsg.create_time)), h('div', {
                  style: 'text-align:center;margin-top:20px;color: #409EFF;cursor: pointer;',
                  on: {
                    click: function click() {
                      layoutRead({
                        ids: fixMsg.id
                      }).then(function (respomse) {
                        that.instance.close();
                        sendmsg().then(function (res) {
                          _this2.newsCliEs = res.data.unread_num;
                          localStorage.setItem('shuliang', _this2.newsCliEs);
                        });
                      });
                    }
                  }
                }, '设为已读')]),
                position: 'bottom-right',
                duration: 0
              });
            } else {
              that.$notify({
                title: fixMsg.title,
                dangerouslyUseHTMLString: true,
                message: "\n                                        <div class=\"lh24 g6 mt20\">\n                                        <div class=\"\">\u6D88\u606F\u5185\u5BB9\uFF1A".concat(fixMsg.content, " </div>\n                                        <div class=\"\">\u6DFB\u52A0\u65F6\u95F4\uFF1A").concat(fixMsg.create_time, "</div>\n                                        <div class=\"tc poi mt20  blueFont\" id=\"btn\">\u8BBE\u7F6E\u6210\u5DF2\u8BFB</div>\n                                        </div>"),
                position: 'bottom-right',
                duration: 10000,
                onClick: function onClick() {
                  layoutRead({
                    ids: fixMsg.id
                  }).then(function (respomse) {
                    // instance.close();
                    sendmsg().then(function (res) {
                      _this2.newsCliEs = res.data.unread_num;
                      localStorage.setItem('shuliang', _this2.newsCliEs);
                    });
                  });
                }
              });
            }
          }
          return _this2.newsCliEs;
        }
        if (fixMsg.type == 'unreadMessage') {
          _this2.$store.dispatch('unreadMessage', fixMsg);
          localStorage.setItem('unreadMessage', JSON.stringify(fixMsg));
          // this.$store.commit('SET_UNREAD_MESSAGE', fixMsg);
        }
      });

      // 后端推送来在线数据时
      socket.on('update_online_count', function (online_stat) {
        console.log(online_stat);
      });
      // });
    }
    // this.socketplan(ids)
    var version = this.version; //版本号（每次上线前需要更新下版本号）
    console.log('当前版本号common.js：', version);
    // if (getTokens() !== undefined && getTokens() !== null) {
    //     getStatusData().then((res) => {
    //         localStorage.setItem('unreadMgs', JSON.stringify(res.data));
    //     });
    // }
    var vvv = '1.8.4';
    console.log('当前版本号app.vue：', vvv);
    if (vvv != version) {
      this.version = vvv;
      localStorage.clear();
    }
    window.onload = function () {
      if (!window.sessionStorage['ISlogin']) {
        // 关闭浏览器
        window.localStorage.removeItem('path');
      } else {
        // 刷新
      }
    };
    window.onunload = function () {
      window.sessionStorage['ISlogin'] = true;
      if (typeof qiConnect !== 'undefined') {
        if (window.isCalling) qiConnect.bye();
        qiConnect.logoff();
      }
    };
    window.onbeforeunload = function () {
      window.sessionStorage['ISlogin'] = true;
    };
  },
  beforeDestroy: function beforeDestroy() {
    clearTimeout(this.timer);
  }
};