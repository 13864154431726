import request from '@/utils/request';
export function fetchList(query) {
  return request({
    url: '/article/list',
    method: 'get',
    params: query
  });
}
export function fetchArticle(id) {
  return request({
    url: '/article/detail',
    method: 'get',
    params: {
      id: id
    }
  });
}
export function fetchPv(pv) {
  return request({
    url: '/article/pv',
    method: 'get',
    params: {
      pv: pv
    }
  });
}
export function createArticle(data) {
  return request({
    url: '/article/create',
    method: 'post',
    data: data
  });
}
export function updateArticle(data) {
  return request({
    url: '/article/update',
    method: 'post',
    data: data
  });
}
// 系统教程
export function SystemManualInfo(data) {
  return request({
    url: '/clientserver.php/SystemManual/info',
    method: 'post',
    data: data
  });
}
export function SystemManualEdit(data) {
  return request({
    url: '/clientserver.php/SystemManual/edit',
    method: 'post',
    data: data
  });
}
export function OperatorDataIndex(data) {
  return request({
    url: '/clientserver.php/OperatorData/index',
    method: 'post',
    data: data
  });
}
export function CallTianRun(data) {
  return request({
    url: '/clientserver.php/CallTianRun/personalList',
    method: 'post',
    data: data
  });
}
export function CallIncoming(data) {
  return request({
    url: '/clientserver.php/CallMaster/personalListByComeIn',
    method: 'post',
    data: data
  });
}
// 销售看板
// 客户相关统计
export function customerStatistics(data) {
  return request({
    url: '/clientserver.php/SalespersonKanban/customer_statistics',
    method: 'post',
    data: data
  });
}
// 缴费相关统计
export function PayStatistics(data) {
  return request({
    url: '/clientserver.php/SalespersonKanban/pay_statistics',
    method: 'post',
    data: data
  });
}
// 收入相关统计
export function IncomeStatistics(data) {
  return request({
    url: '/clientserver.php/SalespersonKanban/income_statistics',
    method: 'post',
    data: data
  });
}
// 数据总量
export function LustomerLevelOverview(data) {
  return request({
    url: '/clientserver.php/SalespersonKanban/customer_overview',
    method: 'post',
    data: data
  });
}
// 数据详情分析表格
export function getListByTree(data) {
  return request({
    url: '/clientserver.php/DataDetail/getListByTree',
    method: 'post',
    data: data
  });
}
// 数据详情分析图标
export function CustomerLevelOverview(data) {
  return request({
    url: '/clientserver.php/SalespersonKanban/customer_level_overview',
    method: 'post',
    data: data
  });
}